'use client';

import { Autocomplete, Grid, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import paises from './nacionalidad';
import tiposDocumento from './tiposDocumento';
import { SaonaDatePicker } from './SaonaDatePicker';

function ClienteForm ({
  register,
  errors,
  control
}: {
  register: any;
  errors: any;
  control: any;
}) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id='apellido'
          label='Apellido'
          fullWidth
          variant='standard'
          autoComplete='off'
          {...register('apellido', {
            required: 'Debe ingresar un valor.'
          })}
          error={!!errors.apellido}
          helperText={errors.apellido?.message}
          inputProps={{ maxLength: 100 }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id='nombre'
          label='Nombre'
          fullWidth
          variant='standard'
          autoComplete='off'
          {...register('nombre', {
            required: 'Debe ingresar un valor.'
          })}
          error={!!errors.nombre}
          helperText={errors.nombre?.message}
          inputProps={{ maxLength: 100 }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SaonaDatePicker
          required
          name='fechaNacimiento'
          control={control}
          label='Fecha Nacimiento'
          defaultValue={null}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name='nacionalidad'
          control={control}
          render={({ field }) => (
            <Autocomplete
              id='nacionalidad'
              disablePortal
              fullWidth
              options={paises}
              getOptionLabel={(option) => option}
              onChange={(_, data) => field.onChange(data)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={field.value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Nacionalidad'
                  variant='standard'
                  {...register('nacionalidad')}
                />
              )}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option}>
                    {option}
                  </li>
                );
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name='tipoDocumento'
          control={control}
          render={({ field }) => (
            <Autocomplete
              id='tipoDocumento'
              disablePortal
              fullWidth
              options={tiposDocumento}
              getOptionLabel={(option) => option}
              onChange={(_, data) => field.onChange(data)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={field.value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  autoComplete='off'
                  label='Tipo Documento'
                  variant='standard'
                  {...register('tipoDocumento', {
                    required: 'Debe ingresar un valor.'
                  })}
                  error={!!errors.tipoDocumento}
                  helperText={errors.tipoDocumento?.message}
                />
              )}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option}>
                    {option}
                  </li>
                );
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id='documento'
          label='Documento'
          fullWidth
          variant='standard'
          autoComplete='off'
          {...register('documento', {
            required: 'Debe ingresar un valor.'
          })}
          error={!!errors.documento}
          helperText={errors.documento?.message}
          inputProps={{ maxLength: 20 }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          id='telefono'
          label='Telefono'
          fullWidth
          variant='standard'
          autoComplete='off'
          {...register('telefono', {
            required: 'Debe ingresar un valor.'
          })}
          error={!!errors.telefono}
          helperText={errors.telefono?.message}
          inputProps={{ maxLength: 20 }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='direccion'
          label='Direccion'
          fullWidth
          variant='standard'
          autoComplete='off'
          {...register('direccion')}
          inputProps={{ maxLength: 200 }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='email'
          label='E-Mail'
          fullWidth
          variant='standard'
          autoComplete='off'
          {...register('email')}
          inputProps={{ maxLength: 100 }}
        />
      </Grid>
    </Grid>
  );
}

export default ClienteForm;
