import dayjs from "dayjs";
import {ContratoFormValues} from "../models/form-values";
import {Search} from "./filtros";
import {OrderByDirection} from "./utils";
import {authHeader} from './api-seguridad';

const BACKEND = process.env.REACT_APP_BACKEND;

export async function findContratos(
    search: Search,
    orderBy: string,
    orderDirection: OrderByDirection,
    page: number,
    pageSize: number
) {
    const api = await fetch(`${BACKEND}/contratos?page=${page}&pageSize=${pageSize}&orderBy=${orderBy}&orderDirection=${orderDirection}&filterBy=${search.label}&filterValue=${search.value}`, {
        method: 'GET',
        headers: {
            'Authorization': authHeader(),
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json'
        }
    });
    const response = await api.json();
    const result = response.data;
    const count = response.pagination.count;
    return {result, count};
}

export async function findContrato(id: string) {
    const api = await fetch(`${BACKEND}/contratos/${id}`, {
        method: 'GET',
        headers: {
            'Authorization': authHeader(),
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json'
        }
    });
    const response = await api.json();
    const result: ContratoFormValues = {
        id: response.data.id,
        sucursal: response.data.sucursal,
        numero: response.data.numero,
        destino: response.data.destino,
        operador: response.data.operador,
        fechaViaje: dayjs(response.data.fechaViaje).toDate(),
        fechaContrato: dayjs(response.data.fechaContrato).toDate(),
        adicionales: response.data.adicionales,
        clienteId: response.data.cliente.id,
        clienteNombre: `${response.data.cliente.apellido}, ${response.data.cliente.nombre}`,
        clienteDireccion: response.data.cliente.direccion,
        clienteTelefono: response.data.cliente.telefono,
        clienteEmail: response.data.cliente.email,
        clienteDocumento: response.data.cliente.documento,
        pasajeros: response.data.pasajeros.map((pasajero: any) => {
            return {
                ...pasajero,
                fechaNacimiento: dayjs(pasajero.fechaNacimiento)
            };
        }),
        transportes: response.data.transportes.map((transporte: any) => {
            return {
                ...transporte,
                fecha: dayjs(transporte.fecha).toDate(),
                sale: dayjs(transporte.fecha + transporte.sale).toDate(),
                llega: dayjs(transporte.fecha + transporte.llega).toDate()
            };
        }),
        hoteles: response.data.hoteles.map((hotel: any) => {
            return {
                ...hotel,
                checkIn: dayjs(hotel.checkIn).toDate(),
                checkOut: dayjs(hotel.checkOut).toDate()
            };
        }),
        traslados: response.data.traslados.map((traslado: any) => {
            return {
                ...traslado,
                fecha: dayjs(traslado.fecha).toDate()
            };
        }),
        excursiones: response.data.excursiones,
        conceptos: response.data.conceptos
    };

    return {result};
}

export async function saveContrato(contrato: ContratoFormValues) {
    let url = null;
    let method = null;
    if (contrato.id === 0) {
        url = `${BACKEND}/contratos`;
        method = 'POST';
    } else {
        url = `${BACKEND}/contratos/${contrato.id}`;
        method = 'PUT';
    }

    const payload = JSON.stringify(contrato);

    const settings = {
        method,
        headers: {
            'Authorization': authHeader(),
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json'
        },
        body: payload
    };

    const api = await fetch(url, settings);

    return await api.json();
}

export async function descargarContratos(
    search: Search,
    orderBy: string,
    orderDirection: OrderByDirection
) {
    const api = await fetch(`${BACKEND}/contratos/descargar?page=0&pageSize=999999&orderBy=${orderBy}&orderDirection=${orderDirection}&filterBy=${search.label}&filterValue=${search.value}`, {
        method: 'GET',
        headers: {
            'Authorization': authHeader(),
            'Cache-Control': 'no-cache',
        },
    });

    const filename = 'contratos.xlsx';
    const blob = await api.blob();

    return {filename, data: blob};
}

export async function descargarContrato(
  id: string,
  numero: number | undefined
) {
    const api = await fetch(`${BACKEND}/contratos/${id}/descargar`, {
        method: 'GET',
        headers: {
            'Authorization': authHeader(),
            'Cache-Control': 'no-cache',
        },
    });

    const filename = `orden_${numero}.xlsx`;
    const blob = await api.blob();

    return {filename, data: blob};
}
