import React from 'react';
import {
  Grid,
  Typography,
  Fab,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TransporteDialog from './transporte-dialog';
import Transporte from '../models/transporte';
import { handleDatePrint, handleTimePrint } from './utils';

function TransporteTable ({
  errors,
  transportesFields,
  transportesAppend,
  transportesRemove,
  transportesUpdate
}: {
  errors: any;
  transportesFields: any;
  transportesAppend: any;
  transportesRemove: any;
  transportesUpdate: any;
}) {
  const [idx, setIdx] = React.useState<number>(0);
  const [transporte, setTransporte] = React.useState<Transporte | undefined>();
  const [transporteDialogOpen, setTransporteDialogOpen] =
    React.useState<boolean>(false);

  const handleSave = (index: number, transporte: Transporte) => {
    setTransporteDialogOpen(false);
    if (index >= 0) {
      transportesUpdate(index, transporte);
    } else {
      transportesAppend(transporte);
    }
  };

  const handleAddTransporte = () => {
    setIdx(-1);
    setTransporte(new Transporte());
    setTransporteDialogOpen(true);
  };

  const handleEditTransporte = (index: number) => {
    setIdx(index);
    setTransporte(transportesFields[index]);
    setTransporteDialogOpen(true);
  };

  const handleRemoveTransporte = (index: number) => {
    transportesRemove(index);
  };

  return (
    <>
      <TransporteDialog
        transporteDialogOpen={transporteDialogOpen}
        setTransporteDialogOpen={setTransporteDialogOpen}
        index={idx}
        transporte={transporte}
        handleSave={handleSave}
      />
      <Grid item xs={12}>
        <Grid
          container
          alignItems='center'
          justifyContent='space-between'
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Typography variant='h6'>Aereos / Bus</Typography>
          </Grid>
          <Grid item>
            <Fab
              size='small'
              color='primary'
              aria-label='add'
              onClick={handleAddTransporte}
            >
              <AddIcon />
            </Fab>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='caption' color='error'>
          {errors.transportes?.root?.message}
        </Typography>
      </Grid>
      {transportesFields.length > 0 && (
        <Grid item xs={12}>
          <TableContainer>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>Compania</TableCell>
                  <TableCell>Vuelo</TableCell>
                  <TableCell>Clase</TableCell>
                  <TableCell>Fecha</TableCell>
                  <TableCell>Origen</TableCell>
                  <TableCell>Destino</TableCell>
                  <TableCell>Sale</TableCell>
                  <TableCell>Llega</TableCell>
                  <TableCell align='right'>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transportesFields.map((transporte: any, index: number) => {
                  return (
                    <TableRow key={transporte.id} hover>
                      <TableCell>{transporte.compania}</TableCell>
                      <TableCell>{transporte.vuelo}</TableCell>
                      <TableCell>{transporte.clase}</TableCell>
                      <TableCell>{handleDatePrint(transporte.fecha)}</TableCell>
                      <TableCell>{transporte.origen}</TableCell>
                      <TableCell>{transporte.destino}</TableCell>
                      <TableCell>{handleTimePrint(transporte.sale)}</TableCell>
                      <TableCell>{handleTimePrint(transporte.llega)}</TableCell>
                      <TableCell align='right'>
                        <IconButton
                          size='small'
                          onClick={() => handleEditTransporte(index)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          size='small'
                          onClick={() => handleRemoveTransporte(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </>
  );
}

export default TransporteTable;
