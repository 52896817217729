import React from 'react';
import {
  Grid,
  Typography,
  Fab,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TrasladoDialog from './traslado-dialog';
import Traslado from '../models/traslado';
import { handleDatePrint } from './utils';

function TrasladoTable ({
  errors,
  trasladosFields,
  trasladosAppend,
  trasladosRemove,
  trasladosUpdate
}: {
  errors: any;
  trasladosFields: any;
  trasladosAppend: any;
  trasladosRemove: any;
  trasladosUpdate: any;
}) {
  const [idx, setIdx] = React.useState<number>(0);
  const [traslado, setTraslado] = React.useState<Traslado | undefined>();
  const [trasladoDialogOpen, setTrasladoDialogOpen] = React.useState<boolean>(false);

  const handleSave = (index: number, traslado: Traslado) => {
    setTrasladoDialogOpen(false);
    if (index >= 0) {
      trasladosUpdate(index, traslado);
    } else {
      trasladosAppend(traslado);
    }
  };

  const handleAddTraslado = () => {
    setIdx(-1);
    setTraslado(new Traslado());
    setTrasladoDialogOpen(true);
  };

  const handleEditTraslado = (index: number) => {
    setIdx(index);
    setTraslado(trasladosFields[index]);
    setTrasladoDialogOpen(true);
  };

  const handleRemoveTraslado = (index: number) => {
    trasladosRemove(index);
  };

  return (
    <>
      <TrasladoDialog
        trasladoDialogOpen={trasladoDialogOpen}
        setTrasladoDialogOpen={setTrasladoDialogOpen}
        index={idx}
        traslado={traslado}
        handleSave={handleSave}
      />
      <Grid item xs={12}>
        <Grid
          container
          alignItems='center'
          justifyContent='space-between'
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Typography variant='h6'>Traslados / Alquiler de Vehículo</Typography>
          </Grid>
          <Grid item>
            <Fab
              size='small'
              color='primary'
              aria-label='add'
              onClick={handleAddTraslado}
            >
              <AddIcon />
            </Fab>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='caption' color='error'>
          {errors.traslados?.root?.message}
        </Typography>
      </Grid>
      {trasladosFields.length > 0 && (
        <Grid item xs={12}>
          <TableContainer>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>Ciudad</TableCell>
                  <TableCell>Fecha</TableCell>
                  <TableCell>Desde</TableCell>
                  <TableCell>Hasta</TableCell>
                  <TableCell>Otros</TableCell>
                  <TableCell align='right'>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {trasladosFields.map((traslado: any, index: number) => {
                  return (
                    <TableRow key={traslado.id} hover>
                      <TableCell>{traslado.ciudad}</TableCell>
                      <TableCell>{handleDatePrint(traslado.fecha)}</TableCell>
                      <TableCell>{traslado.desde}</TableCell>
                      <TableCell>{traslado.hasta}</TableCell>
                      <TableCell>{traslado.otros}</TableCell>
                      <TableCell align='right'>
                        <IconButton
                          size='small'
                          onClick={() => handleEditTraslado(index)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          size='small'
                          onClick={() => handleRemoveTraslado(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </>
  );
}

export default TrasladoTable;
