/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { findContrato } from '../components/api-contrato';
import Loading from '../components/loading';
import SaonaViajesContrato from '../components/pdf/SaonaViajesContrato';
import { ContratoFormValues } from '../models/form-values';

export default function ContratoPrint () {
  const { id } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [contrato, setContrato] = useState<ContratoFormValues>();

  useEffect(() => {
    setLoading(true);
    findContrato(id!).then((data) => {
      setContrato(data.result);
      setLoading(false);
    });
  }, []);

  if (isLoading) return <Loading />;

  return (
    <div>
      <SaonaViajesContrato contrato={contrato!} />
    </div>
  );
}
