export default class Hotel {
  id?: number;
  contratoId?: number;
  checkIn?: Date | null;
  checkOut?: Date | null;
  noches?: number;
  ciudad?: string;
  nombre?: string;
  tipoHabitacion?: string;
};
