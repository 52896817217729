/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import { useForm } from 'react-hook-form';
import Excursion from '../models/excursion';
import { SaonaTextField } from './SaonaTextField';

function ExcursionDialog ({
  index,
  excursion,
  excursionDialogOpen,
  setExcursionDialogOpen,
  handleSave
}: {
  index: number;
  excursion: Excursion | undefined;
  excursionDialogOpen: boolean;
  setExcursionDialogOpen: (open: boolean) => void;
  handleSave: (index: number, excursion: Excursion) => void;
}) {
  const {
    handleSubmit,
    setValue,
    control
  } = useForm<Excursion>({
    defaultValues: {
      ciudad: '',
      comentario: ''
    }
  });

  useEffect(() => {
    if (excursion) {
      setValue('ciudad', excursion.ciudad);
      setValue('comentario', excursion.comentario);
    }
  }, [excursion]);

  const handleGrabar = handleSubmit((data: any) => {
    excursion = { ...data };
    handleSave(index, excursion!);
  });

  const handleClose = () => {
    setExcursionDialogOpen(false);
  };

  return (
    <Dialog open={excursionDialogOpen} onClose={handleClose} disableRestoreFocus>
      <DialogTitle>Datos de Excursion</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <SaonaTextField
              autoFocus
              required
              name='ciudad'
              control={control}
              label='Ciudad'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SaonaTextField
              required={false}
              name='comentario'
              control={control}
              label='Comentario'
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleGrabar}>ACEPTAR</Button>
        <Button onClick={handleClose} color='secondary'>
          CANCELAR
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ExcursionDialog;
