/* eslint-disable react-hooks/exhaustive-deps */
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React, {useEffect, useState} from 'react';
import {useFieldArray, useForm} from 'react-hook-form';
import Loading from '../components/loading';
import {ClienteFormValues, ContratoFormValues} from '../models/form-values';
import {descargarContrato, findContrato, saveContrato} from '../components/api-contrato';
import ContratoForm from '../components/contrato-form';
import {Link, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {findCliente} from '../components/api-cliente';
import {Cancel, FileDownload, Print, Save} from "@mui/icons-material";

export default function ContratoEdit() {
  const {id} = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isLoading, setLoading] = useState(false);
  const [contrato, setContrato] = useState<ContratoFormValues>();
  const [cliente, setCliente] = useState<ClienteFormValues>();

  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
    control
  } = useForm<ContratoFormValues>({
    defaultValues: {
      id: 0,
      sucursal: 0,
      numero: 0,
      destino: '',
      operador: '',
      fechaContrato: undefined,
      fechaViaje: undefined,
      adicionales: '',
      clienteId: '',
      clienteNombre: '',
      clienteDireccion: '',
      clienteTelefono: '',
      clienteEmail: '',
      clienteDocumento: '',
      pasajeros: [],
      transportes: [],
      hoteles: [],
      traslados: [],
      excursiones: [],
      conceptos: []
    }
  });

  const {
    fields: pasajerosFields,
    append: pasajerosAppend,
    remove: pasajerosRemove,
    update: pasajerosUpdate
  } = useFieldArray({
    name: 'pasajeros',
    control,
    rules: {
      required: 'Debe ingresar por lo menos un pasajero.'
    }
  });

  const {
    fields: transportesFields,
    append: transportesAppend,
    remove: transportesRemove,
    update: transportesUpdate
  } = useFieldArray({
    name: 'transportes',
    control
  });

  const {
    fields: hotelesFields,
    append: hotelesAppend,
    remove: hotelesRemove,
    update: hotelesUpdate
  } = useFieldArray({
    name: 'hoteles',
    control
  });

  const {
    fields: trasladosFields,
    append: trasladosAppend,
    remove: trasladosRemove,
    update: trasladosUpdate
  } = useFieldArray({
    name: 'traslados',
    control
  });

  const {
    fields: excursionesFields,
    append: excursionesAppend,
    remove: excursionesRemove,
    update: excursionesUpdate
  } = useFieldArray({
    name: 'excursiones',
    control
  });

  const {
    fields: conceptosFields,
    append: conceptosAppend,
    remove: conceptosRemove,
    update: conceptosUpdate
  } = useFieldArray({
    name: 'conceptos',
    control
  });

  useEffect(() => {
    if (id !== '0') {
      setLoading(true);
      findContrato(id!).then((data) => {
        setContrato(data.result);
        setLoading(false);
      });
    } else {
      const clienteId = searchParams.get('clienteId');
      setLoading(true);
      findCliente(clienteId!).then((data) => {
        setCliente(data.result);
        setLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    if (contrato) {
      setValue('id', contrato.id);
      setValue('numero', contrato.numero);
      setValue('destino', contrato.destino);
      setValue('operador', contrato.operador);
      setValue('fechaViaje', contrato.fechaViaje);
      setValue('adicionales', contrato.adicionales);
      setValue('sucursal', contrato.sucursal);
      setValue('fechaContrato', contrato.fechaContrato);
      setValue('pasajeros', contrato.pasajeros);
      setValue('transportes', contrato.transportes);
      setValue('hoteles', contrato.hoteles);
      setValue('traslados', contrato.traslados);
      setValue('excursiones', contrato.excursiones);
      setValue('conceptos', contrato.conceptos);
      setValue('clienteId', contrato.clienteId);
      setValue('clienteNombre', contrato.clienteNombre);
      setValue('clienteDireccion', contrato.clienteDireccion);
      setValue('clienteTelefono', contrato.clienteTelefono);
      setValue('clienteEmail', contrato.clienteEmail);
      setValue('clienteDocumento', contrato.clienteDocumento);
    }
  }, [contrato, setValue]);

  useEffect(() => {
    if (cliente) {
      setValue('clienteId', cliente.id);
      setValue('clienteNombre', cliente.apellido + ', ' + cliente.nombre);
      setValue('clienteDireccion', cliente.direccion);
      setValue('clienteTelefono', cliente.telefono);
      setValue('clienteEmail', cliente.email);
      setValue('clienteDocumento', cliente.documento);
    }
  }, [cliente, setValue]);

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    delete data.sucursal;
    delete data.clienteNombre;
    delete data.clienteDireccion;
    delete data.clienteTelefono;
    delete data.clienteEmail;
    delete data.clienteDocumento;
    saveContrato(data).then((response) => {
      console.log(response);
      navigate(`/contratos/${response.id}`, {replace: true});
      setLoading(false);
    });
  });

  const handleDescargar = async () => {
    setLoading(true);
    try {
      const response = await descargarContrato(id!, contrato?.numero);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(response.data);
      link.download = response.filename;
      link.click();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) return <Loading/>;

  return (
    <Container component='main' maxWidth='md' sx={{mb: 4}}>
      <Paper
        variant='outlined'
        sx={{my: {xs: 3, md: 6}, p: {xs: 2, md: 3}}}
      >
        <Typography component='h1' variant='h4' align='center' sx={{mb: 2}}>
          Orden de Servicio {id === '0' ? '' : contrato?.numero}
        </Typography>
        <Box component='form' noValidate onSubmit={onSubmit}>
          <ContratoForm
            register={register}
            errors={errors}
            control={control}
            pasajerosFields={pasajerosFields}
            pasajerosAppend={pasajerosAppend}
            pasajerosRemove={pasajerosRemove}
            pasajerosUpdate={pasajerosUpdate}
            transportesFields={transportesFields}
            transportesAppend={transportesAppend}
            transportesRemove={transportesRemove}
            transportesUpdate={transportesUpdate}
            hotelesFields={hotelesFields}
            hotelesAppend={hotelesAppend}
            hotelesRemove={hotelesRemove}
            hotelesUpdate={hotelesUpdate}
            trasladosFields={trasladosFields}
            trasladosAppend={trasladosAppend}
            trasladosRemove={trasladosRemove}
            trasladosUpdate={trasladosUpdate}
            excursionesFields={excursionesFields}
            excursionesAppend={excursionesAppend}
            excursionesRemove={excursionesRemove}
            excursionesUpdate={excursionesUpdate}
            conceptosFields={conceptosFields}
            conceptosAppend={conceptosAppend}
            conceptosRemove={conceptosRemove}
            conceptosUpdate={conceptosUpdate}
          />
          <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
            {id !== '0' &&
                <Button
                    variant='contained'
                    sx={{mt: 3, ml: 1}}
                    onClick={handleDescargar}
                    color='info'
                    startIcon={<FileDownload />}
                >
                    XLSX
                </Button>
            }
            {id !== '0' &&
                <Button
                    variant='contained'
                    sx={{mt: 3, ml: 1}}
                    component={Link}
                    to={`/contratos/${id}/print`}
                    color='info'
                    target='_blank'
                    startIcon={<Print />}
                >
                    PDF
                </Button>
            }
            <Button
              variant='contained'
              onClick={onSubmit}
              sx={{mt: 3, ml: 1}}
              startIcon={<Save />}
            >
              GRABAR
            </Button>
            <Button
              variant='contained'
              sx={{mt: 3, ml: 1}}
              color='secondary'
              component={Link}
              to='/contratos'
              startIcon={<Cancel />}
            >
              CANCELAR
            </Button>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}
