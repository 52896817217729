import dayjs from 'dayjs';

export function dobToString(
  fecha: Date
): string {
  return new Date(fecha).toISOString();
}

export function removeTZ(
  fecha: any
) {
  return fecha.replace('T', ' ').replace('Z', '');
}

export type OrderByDirection = 'asc' | 'desc' | undefined;

export function handleDatePrint(fecha: any) {
  return fecha ? dayjs(fecha).format('DD/MM/YYYY') : '';
}

export function handleTimePrint(hora: any) {
  return hora ? dayjs(hora).format('HH:mm') : '';
}

export function handleDateTimePrint(fecha: any) {
  return fecha ? dayjs(fecha).format('DD/MM/YYYY HH:mm') : '';
}

export function handleMoneyPrint(moneda: string, valor: any) {
  try {
    return Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: moneda
    }).format(valor);
  } catch (ignored) {
    return valor;
  }
}
