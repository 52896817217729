import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import { red } from '@mui/material/colors';
import { useForm } from 'react-hook-form';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { AlertColor, Box, Button, Stack } from '@mui/material';
import { SignUpForm, signup } from '../components/api-seguridad';
import { SaonaTextField } from '../components/SaonaTextField';
import { SaonaAlert } from '../components/SaonaAlert';
import { useState } from 'react';

export default function SignUpPage() {
  const [alertShow, setAlertShow] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>('error');

  const { handleSubmit, control, resetField } = useForm<SignUpForm>({
    defaultValues: {
      email: '',
      password: '',
      sucursal: 0,
      secret: '',
    },
  });

  const onSubmit = handleSubmit(async (form: SignUpForm) => {
    try {
      const result = await signup(form);
      if (result.error) {
        setAlertSeverity('error');
      } else {
        setAlertSeverity('success');
        resetField('email');
        resetField('sucursal');
        resetField('password');
      }
      setAlertMessage(result.message);
      setAlertShow(true);
    } catch (err) {
      console.error(err);
    }
  });

  return (
    <Stack
      direction='row'
      justifyContent='center'
      alignItems='center'
      sx={{ width: 1, height: '100vh' }}
    >
      <Box
        component='form'
        noValidate
        onSubmit={onSubmit}
        sx={{ mt: 1 }}
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        <Card sx={{ maxWidth: 345 }}>
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: red[500] }} aria-label='recipe'>
                <LockOutlinedIcon />
              </Avatar>
            }
            title='NUEVO USUARIO'
          />
          <CardMedia
            component='img'
            // height='194'
            image='/logo-saona-HD.webp'
            alt='Saona Viajes'
          />
          <CardContent>
            <SaonaTextField
              name='email'
              control={control}
              label='Email'
              required
              type='email'
              autoFocus
            />
            <SaonaTextField
              name='sucursal'
              control={control}
              label='Sucursal'
              required
              type='number'
            />
            <SaonaTextField
              name='password'
              control={control}
              label='Password'
              required
              type='password'
            />
            <SaonaTextField
              name='secret'
              control={control}
              label='Secret'
              required
              type='password'
            />
          </CardContent>
          <CardActions disableSpacing>
            <Button
              type='submit'
              fullWidth
              variant='contained'
              sx={{ mt: 3, mb: 2 }}
            >
              Crear Usuario
            </Button>
          </CardActions>
        </Card>
      </Box>
      {alertShow && (
        <SaonaAlert
          message={alertMessage}
          severity={alertSeverity}
          show={alertShow}
          setShowAlert={setAlertShow}
        />
      )}
    </Stack>
  );
}
