/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function LogoutPage () {
  const navigate = useNavigate();
  
  const handleLogout = () => {
    localStorage.removeItem('user');
    navigate("/", { replace: true });
  };

  useEffect(() => {
    setTimeout(() => {
      handleLogout();
    }, 1 * 1000);
  
  }, []);

  return (
    <>
      <Typography variant='h6' sx={{ m: 1 }}>Saliendo...</Typography>
    </>
  );
}
