/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import { useForm } from 'react-hook-form';
import Transporte from '../models/transporte';
import { SaonaTextField } from './SaonaTextField';
import { SaonaDatePicker } from './SaonaDatePicker';
import { SaonaTimePicker } from './SaonaTimePicker';

function TransporteDialog ({
  index,
  transporte,
  transporteDialogOpen,
  setTransporteDialogOpen,
  handleSave
}: {
  index: number;
  transporte: Transporte | undefined;
  transporteDialogOpen: boolean;
  setTransporteDialogOpen: (open: boolean) => void;
  handleSave: (index: number, transporte: Transporte) => void;
}) {
  const {
    handleSubmit,
    setValue,
    control
  } = useForm<Transporte>({
    defaultValues: {
      compania: '',
      vuelo: '',
      clase: '',
      fecha: null,
      origen: '',
      destino: '',
      sale: null,
      llega: null
    }
  });

  useEffect(() => {
    if (transporte) {
      setValue('compania', transporte.compania);
      setValue('vuelo', transporte.vuelo);
      setValue('clase', transporte.clase);
      setValue('fecha', transporte.fecha);
      setValue('origen', transporte.origen);
      setValue('destino', transporte.destino);
      setValue('sale', transporte.sale);
      setValue('llega', transporte.llega);
    }
  }, [transporte]);

  const handleGrabar = handleSubmit((data: any) => {
    transporte = { ...data };
    handleSave(index, transporte!);
  });

  const handleClose = () => {
    setTransporteDialogOpen(false);
  };

  return (
    <Dialog open={transporteDialogOpen} onClose={handleClose} disableRestoreFocus>
      <DialogTitle>Datos de Transporte</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <SaonaTextField
              autoFocus
              required
              name='compania'
              control={control}
              label='Compania'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SaonaTextField
              required={false}
              name='vuelo'
              control={control}
              label='Vuelo'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SaonaTextField
              required={false}
              name='clase'
              control={control}
              label='Clase'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SaonaDatePicker
              required
              name='fecha'
              control={control}
              label='Fecha'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SaonaTextField
              required
              name='origen'
              control={control}
              label='Origen'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SaonaTextField
              required
              name='destino'
              control={control}
              label='Destino'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SaonaTimePicker
              required
              name='sale'
              control={control}
              label='Sale'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SaonaTimePicker
              required
              name='llega'
              control={control}
              label='Llega'
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleGrabar}>ACEPTAR</Button>
        <Button onClick={handleClose} color='secondary'>
          CANCELAR
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TransporteDialog;
