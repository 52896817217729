import { authHeader } from './api-seguridad';

const BACKEND = process.env.REACT_APP_BACKEND;

export async function generateDashboard() {
  const api = await fetch(`${BACKEND}/dashboard`, {
    method: 'GET',
    headers: {
      Authorization: authHeader(),
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
    },
  });
  const response = await api.json();
  return response.data;
}
