import React from 'react';
import {Text, View, StyleSheet} from '@react-pdf/renderer';
import Transporte from '../../models/transporte';
import Hotel from '../../models/hotel';
import Traslado from '../../models/traslado';
import Excursion from '../../models/excursion';
import Concepto from '../../models/concepto';
import Pasajero from '../../models/pasajero';
import {handleDatePrint, handleMoneyPrint, handleTimePrint} from '../utils';
import {ContratoFormValues} from '../../models/form-values';

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  title: {
    fontFamily: 'Helvetica-Bold',
    fontSize: 12
  },
  subtitle: {
    fontFamily: 'Helvetica-Bold',
    fontSize: 8
  },
  section: {
    marginTop: 5
  },
  fila: {
    display: 'flex',
    marginTop: 2,
    flexDirection: 'row'
  },
  leftColumn: {
    width: '30%'
  },
  rightColumn: {
    width: '70%'
  },
  texto: {
    fontFamily: 'Helvetica',
    fontSize: 8
  },
  textoBold: {
    fontFamily: 'Helvetica-Bold',
    fontSize: 8
  },
  table: {
    display: "flex",
    width: "auto",
    border: '0.5 solid darkgrey',
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 5
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row"
  },
  tableCol: {
    border: '0.5 solid darkgrey',
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCellHeader: {
    margin: "auto",
    marginTop: 2,
    fontFamily: 'Helvetica-Bold',
    fontSize: 8
  },
  tableCell: {
    margin: "auto",
    marginTop: 2,
    fontFamily: 'Helvetica',
    fontSize: 8
  },
  footer: {
    fontFamily: 'Helvetica',
    fontSize: 8,
    textAlign: 'center'
  },
  tableTotal: {
    display: "flex",
    width: "auto",
    marginTop: 5
  },
  tableTotalCol: {
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableTotalCellHeader: {
    margin: "auto",
    marginTop: 2,
    fontFamily: 'Helvetica-Bold',
    fontSize: 12
  },

});

const ContratoHeader = ({
                          contrato
                        }: {
  contrato: ContratoFormValues;
}) => {
  return (
    <View style={styles.section}>
      <View style={styles.fila}>
        <View style={styles.leftColumn}>
          <Text style={styles.subtitle}>Solicitud Nro</Text>
        </View>
        <View style={[styles.rightColumn, {backgroundColor: '#aaa', width: '200', padding: 1}]}>
          <Text style={styles.textoBold}>{contrato?.numero}</Text>
        </View>
      </View>
      <View style={styles.fila}>
        <View style={styles.leftColumn}>
          <Text style={styles.subtitle}>Apellido y Nombre</Text>
        </View>
        <View style={styles.rightColumn}>
          <Text style={styles.textoBold}>{contrato?.clienteNombre}</Text>
        </View>
      </View>
      <View style={styles.fila}>
        <View style={styles.leftColumn}>
          <Text style={styles.subtitle}>Dirección</Text>
        </View>
        <View style={styles.rightColumn}>
          <Text style={styles.texto}>{contrato?.clienteDireccion}</Text>
        </View>
      </View>
      <View style={styles.fila}>
        <View style={styles.leftColumn}>
          <Text style={styles.subtitle}>Teléfono</Text>
        </View>
        <View style={styles.rightColumn}>
          <Text style={styles.texto}>{contrato?.clienteTelefono}</Text>
        </View>
      </View>
      <View style={styles.fila}>
        <View style={styles.leftColumn}>
          <Text style={styles.subtitle}>Fecha de Viaje</Text>
        </View>
        <View style={styles.rightColumn}>
          <Text style={styles.textoBold}>
            {handleDatePrint(contrato?.fechaViaje)}
          </Text>
        </View>
      </View>
      <View style={styles.fila}>
        <View style={styles.leftColumn}>
          <Text style={styles.subtitle}>Email</Text>
        </View>
        <View style={styles.rightColumn}>
          <Text style={styles.texto}>{contrato?.clienteEmail}</Text>
        </View>
      </View>
    </View>
  );
};

const Title = ({texto}: { texto: any }) => {
  return (
    <Text style={styles.title} x={0} y={0}>{texto}</Text>
  );
}

const TableCellHeader = ({width, texto}: { width: string, texto: any }) => {
  return (
    <View style={[styles.tableCol, {width: width}]}>
      <Text style={styles.tableCellHeader} x={''} y={''}>{texto}</Text>
    </View>
  );
}

const TableCell = ({width, texto}: { width: string, texto: any }) => {
  return (
    <View style={[styles.tableCol, {width: width}]}>
      <Text style={styles.tableCell} x={''} y={''}>{texto}</Text>
    </View>
  );
}

const DatosPasajeros = ({pasajeros}: { pasajeros: Pasajero[] }) => {
  return (
    <View style={styles.section}>
      <Title texto={'Datos de Pasajeros'}/>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <TableCellHeader width={'20%'} texto={'Apellido'}/>
          <TableCellHeader width={'20%'} texto={'Nombre'}/>
          <TableCellHeader width={'10%'} texto={'Fec. Nac'}/>
          <TableCellHeader width={'20%'} texto={'Nacionalidad'}/>
          <TableCellHeader width={'10%'} texto={'Tipo'}/>
          <TableCellHeader width={'10%'} texto={'Documento'}/>
          <TableCellHeader width={'10%'} texto={'CUIL/CUIT'}/>
        </View>
        {pasajeros?.map((pasajero) => {
          return (
            <View style={styles.tableRow}>
              <TableCell width={'20%'} texto={pasajero.apellido}/>
              <TableCell width={'20%'} texto={pasajero.nombre}/>
              <TableCell width={'10%'} texto={handleDatePrint(pasajero.fechaNacimiento)}/>
              <TableCell width={'20%'} texto={pasajero.nacionalidad}/>
              <TableCell width={'10%'} texto={pasajero.tipoDocumento}/>
              <TableCell width={'10%'} texto={pasajero.documento}/>
              <TableCell width={'10%'} texto={pasajero.cuil}/>
            </View>
          );
        })}
      </View>
    </View>
  );
};

const DatosTransportes = ({transportes}: { transportes: Transporte[] }) => {
  return (
    <View style={styles.section}>
      <Title texto={'Aereos / Bus'}/>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <TableCellHeader width={'13%'} texto={'Compania'}/>
          <TableCellHeader width={'13%'} texto={'Vuelo'}/>
          <TableCellHeader width={'9%'} texto={'Clase'}/>
          <TableCellHeader width={'13%'} texto={'Fecha'}/>
          <TableCellHeader width={'13%'} texto={'Origen'}/>
          <TableCellHeader width={'13%'} texto={'Destino'}/>
          <TableCellHeader width={'13%'} texto={'Sale'}/>
          <TableCellHeader width={'13%'} texto={'Llega'}/>
        </View>
        {transportes?.map((transporte) => {
          return (
            <View style={styles.tableRow}>
              <TableCell width={'13%'} texto={transporte.compania}/>
              <TableCell width={'13%'} texto={transporte.vuelo}/>
              <TableCell width={'9%'} texto={transporte.clase}/>
              <TableCell width={'13%'} texto={handleDatePrint(transporte.fecha)}/>
              <TableCell width={'13%'} texto={transporte.origen}/>
              <TableCell width={'13%'} texto={transporte.destino}/>
              <TableCell width={'13%'} texto={handleTimePrint(transporte.sale)}/>
              <TableCell width={'13%'} texto={handleTimePrint(transporte.llega)}/>
            </View>
          );
        })}
      </View>
    </View>
  );
};

const DatosHoteles = ({hoteles}: { hoteles: Hotel[] }) => {
  return (
    <View style={styles.section}>
      <Title texto={'Hoteles'}/>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <TableCellHeader width={'10%'} texto={'Check-In'}/>
          <TableCellHeader width={'10%'} texto={'Check-Out'}/>
          <TableCellHeader width={'10%'} texto={'Noches'}/>
          <TableCellHeader width={'20%'} texto={'Ciudad'}/>
          <TableCellHeader width={'24%'} texto={'Nombre'}/>
          <TableCellHeader width={'26%'} texto={'Habitación'}/>
        </View>
        {hoteles?.map((hotel) => {
          return (
            <View style={styles.tableRow}>
              <TableCell width={'10%'} texto={handleDatePrint(hotel.checkIn)}/>
              <TableCell width={'10%'} texto={handleDatePrint(hotel.checkOut)}/>
              <TableCell width={'10%'} texto={hotel.noches}/>
              <TableCell width={'20%'} texto={hotel.ciudad}/>
              <TableCell width={'24%'} texto={hotel.nombre}/>
              <TableCell width={'26%'} texto={hotel.tipoHabitacion}/>
            </View>
          );
        })}
      </View>
    </View>
  );
};

const DatosTraslado = ({traslados}: { traslados: Traslado[] }) => {
  return (
    <View style={styles.section}>
      <Title texto={'Traslados / Alquiler de Vehículo'}/>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <TableCellHeader width={'20%'} texto={'Ciudad'}/>
          <TableCellHeader width={'20%'} texto={'Fecha'}/>
          <TableCellHeader width={'20%'} texto={'Desde'}/>
          <TableCellHeader width={'20%'} texto={'Hasta'}/>
          <TableCellHeader width={'20%'} texto={'Otros'}/>
        </View>
        {traslados?.map((traslado) => {
          return (
            <View style={styles.tableRow}>
              <TableCell width={'20%'} texto={traslado.ciudad}/>
              <TableCell width={'20%'} texto={handleDatePrint(traslado.fecha)}/>
              <TableCell width={'20%'} texto={traslado.desde}/>
              <TableCell width={'20%'} texto={traslado.hasta}/>
              <TableCell width={'20%'} texto={traslado.otros}/>
            </View>
          );
        })}
      </View>
    </View>
  );
};

const DatosExcursion = ({excursiones}: { excursiones: Excursion[] }) => {
  return (
    <View style={styles.section}>
      <Title texto={'Excursiones'}/>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <TableCellHeader width={'20%'} texto={'Ciudad'}/>
          <TableCellHeader width={'80%'} texto={'Comentario'}/>
        </View>
        {excursiones?.map((excursion) => {
          return (
            <View style={styles.tableRow}>
              <TableCell width={'20%'} texto={excursion.ciudad}/>
              <TableCell width={'80%'} texto={excursion.comentario}/>
            </View>
          );
        })}
      </View>
    </View>
  );
};

const DatosConcepto = ({conceptos}: { conceptos: Concepto[] }) => {
  return (
    <View style={styles.section}>
      <Title texto={'Precio por Pasajero'}/>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <TableCellHeader width={'33%'} texto={'Concepto'}/>
          <TableCellHeader width={'33%'} texto={'Moneda'}/>
          <TableCellHeader width={'33%'} texto={'Valor'}/>
        </View>
        {conceptos?.map((concepto) => {
          return (
            <View style={styles.tableRow}>
              <TableCell width={'33%'} texto={concepto.concepto}/>
              <TableCell width={'33%'} texto={concepto.moneda}/>
              <TableCell width={'33%'} texto={handleMoneyPrint(concepto.moneda as string, concepto.valor)}/>
            </View>
          );
        })}
      </View>
    </View>
  );
};

const ServiciosAdicionales = ({contrato}: { contrato: ContratoFormValues; }) => {
  return (
    <View style={styles.section}>
      <Title texto={'Servicios Adicionales / Observaciones'}/>
      <View style={styles.section}>
        <Text style={styles.texto} x={0} y={0}>
          {contrato?.adicionales}
        </Text>
      </View>
    </View>
  );
};

const Total = ({conceptos}: { conceptos: Concepto[] }) => {
  const mapaSumas = conceptos.reduce((acc, concepto) => {
    const moneda = concepto.moneda as string;
    if (acc[moneda]) {
      acc[moneda] += concepto.valor as number;
    } else {
      acc[moneda] = concepto.valor as number;
    }
    return acc;
  }, {} as Record<string, number>);

  const totales = Object.keys(mapaSumas).map(moneda => ({ moneda, valor: mapaSumas[moneda], }));

  return (
    <View style={styles.section}>
      <View style={styles.tableTotal}>
        <View style={styles.tableRow}>
          <View style={[styles.tableTotalCol, {width: '50%'}]}>
            <Text style={styles.tableTotalCellHeader} x={''} y={''}>VALOR TOTAL</Text>
          </View>
          <View style={[styles.tableTotalCol, {width: '50%'}]}>
            <View style={styles.table}>
              {totales.map((total) => {
                return (
                  <View style={styles.tableRow}>
                    <TableCell width={'20%'} texto={total.moneda}/>
                    <TableCell width={'80%'} texto={handleMoneyPrint(total.moneda as string, total.valor)}/>
                  </View>
                );
              })}
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

const Footer = () => {
  return (
    <View style={[styles.section, {marginTop: 10}]}>
      <Text style={styles.footer} x={0} y={0}>Orden de Servicio sujeta a Condiciones Generales disponibles en nuestro sitio web www.saonaviajes.com.ar</Text>
    </View>
  );
}

const ContratoPdf = ({contrato}: { contrato: ContratoFormValues }) => (
  <View style={styles.container}>
    <Text style={styles.title}>Orden de Servicio</Text>
    <ContratoHeader contrato={contrato}/>
    <DatosPasajeros pasajeros={contrato.pasajeros}/>
    {contrato.transportes && contrato.transportes.length > 0 && <DatosTransportes transportes={contrato.transportes}/>}
    {contrato.hoteles && contrato.hoteles.length > 0 && <DatosHoteles hoteles={contrato.hoteles}/>}
    {contrato.traslados && contrato.traslados.length > 0 && <DatosTraslado traslados={contrato.traslados}/>}
    {contrato.excursiones && contrato.excursiones.length > 0 && <DatosExcursion excursiones={contrato.excursiones}/>}
    {contrato.adicionales && <ServiciosAdicionales contrato={contrato}/>}
    {contrato.conceptos && contrato.conceptos.length > 0 && <DatosConcepto conceptos={contrato.conceptos}/>}
    {contrato.conceptos && contrato.conceptos.length > 0 && <Total conceptos={contrato.conceptos}/>}
    <Footer />
  </View>
);

export default ContratoPdf;
