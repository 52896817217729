import {ClienteFormValues} from '../models/form-values';
import {authHeader} from './api-seguridad';
import {Search} from './filtros';
import {OrderByDirection} from './utils';

const BACKEND = process.env.REACT_APP_BACKEND;

export async function findClientes(
    search: Search,
    orderBy: string,
    orderDirection: OrderByDirection,
    page: number,
    pageSize: number
) {
    const api = await fetch(`${BACKEND}/clientes?page=${page}&pageSize=${pageSize}&orderBy=${orderBy}&orderDirection=${orderDirection}&filterBy=${search.label}&filterValue=${search.value}`, {
        method: 'GET',
        headers: {
            'Authorization': authHeader(),
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json'
        }
    });

    const response = await api.json();
    const result = response.data;
    const count = response.pagination.count;
    return {result, count};
}

export async function findCliente(id: string) {
    const api = await fetch(`${BACKEND}/clientes/${id}`, {
        method: 'GET',
        headers: {
            'Authorization': authHeader(),
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json'
        }
    });
    const response = await api.json();
    const result: ClienteFormValues = {
        id: response.data.id,
        sucursal: response.data.sucursal,
        apellido: response.data.apellido,
        nombre: response.data.nombre,
        fechaNacimiento: response.data.fechaNacimiento,
        nacionalidad: response.data.nacionalidad,
        tipoDocumento: response.data.tipoDocumento,
        documento: response.data.documento,
        direccion: response.data.direccion,
        telefono: response.data.telefono,
        email: response.data.email,
        createdAt: response.data.createdAt,
        createdBy: response.data.createdBy,
        updatedAt: response.data.updatedAt,
        updatedBy: response.data.updatedBy,
        deletedAt: response.data.deletedAt,
        deletedBy: response.data.deletedBy
    };
    return {result};
}

export async function saveCliente(cliente: ClienteFormValues) {
    let url = null;
    let method = null;
    if (cliente.id === '0') {
        url = `${BACKEND}/clientes`
        method = 'POST';
    } else {
        url = `${BACKEND}/clientes/${cliente.id}`;
        method = 'PUT';
    }

    const settings = {
        method,
        headers: {
            'Authorization': authHeader(),
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(cliente)
    };

    const api = await fetch(url, settings);
    const result = api.json();
    return {result};
}

export async function descargarClientes(
    search: Search,
    orderBy: string,
    orderDirection: OrderByDirection
) {
    const api = await fetch(`${BACKEND}/clientes/descargar?page=0&pageSize=999999&orderBy=${orderBy}&orderDirection=${orderDirection}&filterBy=${search.label}&filterValue=${search.value}`, {
        method: 'GET',
        headers: {
            'Authorization': authHeader(),
            'Cache-Control': 'no-cache',
        },
    });

    const filename = 'clientes.xlsx';
    const blob = await api.blob();

    return {filename, data: blob};
}
