// import { Roboto } from 'next/font/google';
import { createTheme } from '@mui/material/styles';
import {orange, teal} from "@mui/material/colors";
// import type {} from '@mui/x-date-pickers/themeAugmentation';

// export const roboto = Roboto({
//   weight: ['300', '400', '500', '700'],
//   subsets: ['latin'],
//   display: 'swap',
//   fallback: ['Helvetica', 'Arial', 'sans-serif']
// });

// Create a theme instance.
const theme = createTheme({
    palette: {
        info: orange,
    },
  // typography: {
  //   fontFamily: roboto.style.fontFamily
  // }
  // },
  // components: {
  //   MuiDatePicker: {
  //     styleOverrides: {
  //       root: {
  //         backgroundColor: 'red'
  //       }
  //     }
  //   }
  // }
});

export default theme;
