import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {Link, useRouteLoaderData} from 'react-router-dom';
import {UserData} from './api-seguridad';
import {AnalyticsOutlined, Groups, Home, Logout, Public} from "@mui/icons-material";
import {Icon} from "@mui/material";

function Navbar() {
  const user = useRouteLoaderData('root') as UserData;
  const showDashboard = user && user.admin;
  return (
    <>
      {user && (
        <AppBar
          position='static'
          color='primary'
          elevation={0}
          sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
        >
          <Toolbar sx={{ flexWrap: 'wrap' }}>
            <Button
              color='inherit'
              component={Link}
              to='/'
              size='large'
              sx={{ p: 0, pr: 1 }}
              startIcon={<Home />}
            >
              Saona Viajes |
            </Button>
            {showDashboard ? (
              <Button
                color='inherit'
                component={Link}
                to='/dashboard'
                size='large'
                sx={{ p: 0, pr: 1 }}
                startIcon={<AnalyticsOutlined />}
              >
                Dashboard |
              </Button>
            ) : ''}
            <Typography
              variant='button'
              color='inherit'
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {user.sucursal === 1 ? 'Lomas de Zamora' : 'Adrogué'} |{' '}
              {user.email}
            </Typography>
            <nav>
              <Button
                color='inherit'
                sx={{ my: 1, mx: 1.5 }}
                component={Link}
                to='/clientes'
                startIcon={<Groups />}
              >
                Clientes
              </Button>
              <Button
                color='inherit'
                sx={{ my: 1, mx: 1.5 }}
                component={Link}
                to='/contratos'
                startIcon={<Public />}
              >
                Contratos
              </Button>
            </nav>
            <Button
              variant='outlined'
              color='inherit'
              sx={{ my: 1, mx: 1.5 }}
              component={Link}
              to='/logout'
              startIcon={<Logout />}
            >
              Salir
            </Button>
          </Toolbar>
        </AppBar>
      )}
    </>
  );
}

export default Navbar;
