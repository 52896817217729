export default class Pasajero {
  id?: number;
  contratoId?: number;
  nombre?: string;
  apellido?: string;
  fechaNacimiento?: Date | null;
  nacionalidad?: string | null;
  tipoDocumento?: string | null;
  documento?: string;
  cuil?: string;
};
