import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';

interface FormInputProps {
  name: string;
  control: any;
  label: string;
  setValue?: any;
  required: boolean;
  autoFocus?: boolean;
  type?: string;
}

export const SaonaTextField = ({
  name,
  control,
  label,
  required,
  autoFocus,
  type
}: FormInputProps) => {
  let rules;
  if (required) {
    rules = {
      required: 'Debe ingresar un valor'
    };
  }
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState
      }) => (
        <TextField
          autoFocus={autoFocus}
          helperText={error ? error.message : null}
          size='small'
          error={!!error}
          onChange={onChange}
          value={value}
          fullWidth
          label={label}
          variant='standard'
          required={required}
          autoComplete='off'
          type={type}
        />
      )}
    />
  );
};
