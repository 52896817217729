import { ActionFunction, redirect } from 'react-router-dom';
import { Form, useActionData, useNavigation } from 'react-router-dom';
import { useState } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import { orange } from '@mui/material/colors';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Box, Button, Stack, TextField } from '@mui/material';
import { SaonaAlert } from '../components/SaonaAlert';
import { LoginResponse, login } from '../components/api-seguridad';

export default function AuthenticationPage() {
  const data = useActionData() as LoginResponse;
  const [showAlert, setShowAlert] = useState<boolean>(data && data.error);
  const navigation = useNavigation();

  const isSubmitting = navigation.state === 'submitting';

  return (
    <Stack
      direction='row'
      justifyContent='center'
      alignItems='center'
      sx={{ width: 1, height: '100vh' }}
    >
      <Form method='post'>
        <Box
          component='section'
          sx={{ mt: 1 }}
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <Card sx={{ maxWidth: 345 }}>
            <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: orange[800] }}>
                  <LockOutlinedIcon />
                </Avatar>
              }
              title='LOGIN'
            />
            <CardMedia
              component='img'
              image='/logo-saona-HD.webp'
              alt='Saona Viajes'
              sx={{ p: 1 }}
            />
            <CardContent>
              <TextField
                required
                id='email'
                name='email'
                label='Email'
                fullWidth
                variant='standard'
                autoComplete='off'
              />
              <TextField
                required
                id='password'
                name='password'
                label='Password'
                fullWidth
                variant='standard'
                autoComplete='off'
                type='password'
              />
            </CardContent>
            <CardActions disableSpacing>
              <Button
                type='submit'
                fullWidth
                variant='contained'
                sx={{ mt: 3, mb: 2 }}
                disabled={isSubmitting}
              >
                Ingresar
              </Button>
            </CardActions>
          </Card>
        </Box>
        {data && !data.success && (
          <SaonaAlert
            message='Credenciales Invalidas'
            severity='error'
            show={showAlert}
            setShowAlert={setShowAlert}
          />
        )}
      </Form>
    </Stack>
  );
}

export const authAction: ActionFunction = async ({ request }) => {
  const data = await request.formData();

  const authData = {
    email: data.get('email'),
    password: data.get('password'),
  };

  const signInResponse = await login(
    authData.email as string,
    authData.password as string
  );

  if (!signInResponse.success) {
    return { error: true };
  }

  localStorage.setItem('user', JSON.stringify(signInResponse.user));

  return redirect('/');
};
