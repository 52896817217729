import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';

interface FormInputProps {
  name: string;
  control: any;
  label: string;
  setValue?: any;
  required: boolean;
  options: string[];
}

export const SaonaAutocomplete = ({
  name,
  control,
  label,
  required,
  options
}: FormInputProps) => {
  let rules;
  if (required) {
    rules = {
      required: 'Debe ingresar un valor'
    };
  }
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState
      }) => (
        <Autocomplete
          disablePortal
          fullWidth
          options={options}
          getOptionLabel={(option) => option}
          onChange={(_, data) => onChange(data)}
          isOptionEqualToValue={(option, value) => option === value}
          value={value}
          renderInput={(params) => (
            <TextField
              {...params}
              required={required}
              autoComplete='off'
              label={label}
              variant='standard'
              error={!!error}
              helperText={error ? error.message : null}
            />
          )}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option}>
                {option}
              </li>
            );
          }}
        />
      )}
    />
  );
};
