/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import { useForm } from 'react-hook-form';
import Hotel from '../models/hotel';
import { SaonaTextField } from './SaonaTextField';
import { SaonaDatePicker } from './SaonaDatePicker';

function HotelDialog ({
  index,
  hotel,
  hotelDialogOpen,
  setHotelDialogOpen,
  handleSave
}: {
  index: number;
  hotel: Hotel | undefined;
  hotelDialogOpen: boolean;
  setHotelDialogOpen: (open: boolean) => void;
  handleSave: (index: number, hotel: Hotel) => void;
}) {
  const {
    handleSubmit,
    setValue,
    control
  } = useForm<Hotel>({
    defaultValues: {
      checkIn: null,
      checkOut: null,
      noches: 0,
      ciudad: '',
      nombre: '',
      tipoHabitacion: ''
    }
  });

  useEffect(() => {
    if (hotel) {
      setValue('checkIn', hotel.checkIn);
      setValue('checkOut', hotel.checkOut);
      setValue('noches', hotel.noches);
      setValue('ciudad', hotel.ciudad);
      setValue('nombre', hotel.nombre);
      setValue('tipoHabitacion', hotel.tipoHabitacion);
    }
  }, [hotel]);

  const handleGrabar = handleSubmit((data : any) => {
    hotel = { ...data };
    handleSave(index, hotel!);
  });

  const handleClose = () => {
    setHotelDialogOpen(false);
  };

  return (
    <Dialog open={hotelDialogOpen} onClose={handleClose} disableRestoreFocus>
      <DialogTitle>Datos de Hotel</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <SaonaDatePicker
              autoFocus
              required
              name='checkIn'
              control={control}
              label='Check-In'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SaonaDatePicker
              required
              name='checkOut'
              control={control}
              label='Check-Out'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SaonaTextField
              required
              name='noches'
              control={control}
              label='Noches'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SaonaTextField
              required
              name='ciudad'
              control={control}
              label='Ciudad'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SaonaTextField
              required
              name='nombre'
              control={control}
              label='Nombre'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SaonaTextField
              required
              name='tipoHabitacion'
              control={control}
              label='Habitación'
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleGrabar}>ACEPTAR</Button>
        <Button onClick={handleClose} color='secondary'>CANCELAR</Button>
      </DialogActions>
    </Dialog>
  );
}

export default HotelDialog;
