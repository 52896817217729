/* eslint-disable react-hooks/exhaustive-deps */
import React, {ChangeEvent, MouseEvent, useEffect, useState} from 'react';
import {
  AppBar,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import {CancelRounded, FileDownload, TravelExplore} from '@mui/icons-material';
import Loading from '../components/loading';
import {OrderByDirection} from '../components/utils';
import {Search} from '../components/filtros';
import {descargarContratos, findContratos} from '../components/api-contrato';
import Contrato from '../models/contrato';
import {Link} from 'react-router-dom';

export default function ContratoHome () {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [contratos, setContratos] = useState<Contrato[]>([]);
  const [search, setSearch] = useState<Search>({ label: '', value: '' });
  const [orderBy, setOrderBy] = useState<string>('numero');
  const [orderDirection, setOrderDirection] = useState<OrderByDirection>('desc');
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(5);
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    handleSearch(search, orderBy, orderDirection, page, pageSize);
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  const handleSearch = (
    search: Search,
    orderBy: string,
    orderDirection: OrderByDirection,
    page: number,
    pageSize: number
  ) => {
    setLoading(true);
    findContratos(search, orderBy, orderDirection, page, pageSize).then((data) => {
      setContratos(data.result);
      setCount(data.count);
      setLoading(false);
    });
  };

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    handleSearch(search, orderBy, orderDirection, newPage, pageSize);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const newPageSize = parseInt(event.target.value, 10);
    setPageSize(newPageSize);
    handleSearch(search, orderBy, orderDirection, page, newPageSize);
  };

  const handleDescargar = () => {
    setLoading(true);
    descargarContratos(search, orderBy, orderDirection).then( (response) => {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(response.data);
      link.download = response.filename;
      link.click();
      setLoading(false);
    });
  };

  const handleSortRequest =
    (property: keyof any) =>
      (event: MouseEvent<HTMLTableCellElement>) => {
        let newOrderBy = orderBy;
        let newOrderDirection = orderDirection;
        if (property.toString() === orderBy) {
          newOrderDirection = orderDirection === 'asc' ? 'desc' : 'asc';
          setOrderDirection(newOrderDirection);
        } else {
          newOrderDirection = 'asc';
          setOrderDirection(newOrderDirection);
          newOrderBy = property.toString();
          setOrderBy(newOrderBy);
        }
        handleSearch(search, newOrderBy, newOrderDirection, page, pageSize);
      };

  const handleFilterValueChange = (event: any) => {
    setSearch({ label: search.label, value: event.target.value });
  };

  const handleFilterValueClear = (event: any) => {
    const emptySearch = { label: search.label, value: '' };
    setPage(0);
    setSearch(emptySearch);
    handleSearch(emptySearch, orderBy, orderDirection, 0, pageSize);
  };

  const handleFilterSearch = () => {
    setPage(0);
    handleSearch(search, 'numero', 'desc', 0, pageSize);
  };

  if (isLoading) return <Loading />;
  if (!contratos) return <p>No contratos data</p>;

  return (
    <Paper sx={{ margin: 'auto', overflow: 'hidden', m: 2 }}>
      <AppBar
        position='static'
        color='default'
        elevation={0}
        sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
      >
        <Toolbar>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs>
              <TextField
                id='filter'
                name='filter'
                autoComplete='off'
                placeholder='Buscar por número, destino, apellido, nombre, documento, telefono, dirección, email'
                type='text'
                variant='standard'
                fullWidth
                onChange={handleFilterValueChange}
                value={search.value}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: search.value && (
                    <IconButton
                      onClick={handleFilterValueClear}
                    ><CancelRounded />
                    </IconButton>
                  )
                }}
              />
            </Grid>
            <Grid item>
              <Button
                disabled={search.label === '' && search.value === ''}
                variant='contained'
                sx={{ mr: 1 }}
                onClick={handleFilterSearch}
                startIcon={<TravelExplore />}
              >
                BUSCAR
              </Button>
              <Button
                variant='contained'
                sx={{ mr: 1 }}
                onClick={handleDescargar}
                color='info'
                startIcon={<FileDownload />}
              >
                DESCARGAR
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell key='numero' component='th'>
                <TableSortLabel
                  active={orderBy === 'numero'}
                  onClick={handleSortRequest('numero')}
                  direction={orderBy === 'numero' ? orderDirection : 'asc'}
                >
                  Número
                </TableSortLabel>
              </TableCell>
              <TableCell key='clienteNombre' component='th'>
                <TableSortLabel
                  active={orderBy === 'clienteNombre'}
                  onClick={handleSortRequest('clienteNombre')}
                  direction={orderBy === 'clienteNombre' ? orderDirection : 'asc'}
                >
                  Cliente
                </TableSortLabel>
              </TableCell>
              <TableCell key='clienteDocumento' component='th'>
                <TableSortLabel
                  active={orderBy === 'clienteDocumento'}
                  onClick={handleSortRequest('clienteDocumento')}
                  direction={orderBy === 'clienteDocumento' ? orderDirection : 'asc'}
                >
                  Documento
                </TableSortLabel>
              </TableCell>
              <TableCell key='destino' component='th'>
                <TableSortLabel
                  active={orderBy === 'destino'}
                  onClick={handleSortRequest('destino')}
                  direction={orderBy === 'destino' ? orderDirection : 'asc'}
                >
                  Destino
                </TableSortLabel>
              </TableCell>
              <TableCell key='createdAt' component='th'>
                <TableSortLabel
                  active={orderBy === 'createdAt'}
                  onClick={handleSortRequest('createdAt')}
                  direction={orderBy === 'createdAt' ? orderDirection : 'asc'}
                >
                  Creado
                </TableSortLabel>
              </TableCell>
              <TableCell key='createdBy' component='th'>
                <TableSortLabel
                  active={orderBy === 'createdBy'}
                  onClick={handleSortRequest('createdBy')}
                  direction={orderBy === 'createdBy' ? orderDirection : 'asc'}
                >
                  Usuario
                </TableSortLabel>
              </TableCell>
              <TableCell key='updatedAt' component='th'>
                <TableSortLabel
                  active={orderBy === 'updatedAt'}
                  onClick={handleSortRequest('updatedAt')}
                  direction={orderBy === 'updatedAt' ? orderDirection : 'asc'}
                >
                  Modificado
                </TableSortLabel>
              </TableCell>
              <TableCell key='updatedBy' component='th'>
                <TableSortLabel
                  active={orderBy === 'updatedBy'}
                  onClick={handleSortRequest('updatedBy')}
                  direction={orderBy === 'updatedBy' ? orderDirection : 'asc'}
                >
                  Usuario
                </TableSortLabel>
              </TableCell>
              <TableCell component='th' />
            </TableRow>
          </TableHead>
          <TableBody>
            {contratos.map((contrato: Contrato) => (
              <TableRow key={contrato.id} hover>
                <TableCell scope='row'>{contrato.numero}</TableCell>
                <TableCell>{contrato.cliente?.fullName.toString()}</TableCell>
                <TableCell>{contrato.cliente?.documento}</TableCell>
                <TableCell>{contrato.destino}</TableCell>
                <TableCell>{contrato.createdAt}</TableCell>
                <TableCell>{contrato.createdBy}</TableCell>
                <TableCell>{contrato.updatedAt}</TableCell>
                <TableCell>{contrato.updatedBy}</TableCell>
                <TableCell>
                  <Link to={`/contratos/${contrato.id}`}>
                    <IconButton size='small'>
                      <EditIcon />
                    </IconButton>
                  </Link> 
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={pageSize}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
}
