import { useState } from 'react';
import {
  Grid,
  Typography,
  Fab,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ExcursionDialog from './excursion-dialog';
import Excursion from '../models/excursion';

function ExcursionTable ({
  errors,
  excursionesFields,
  excursionesAppend,
  excursionesRemove,
  excursionesUpdate
}: {
  errors: any;
  excursionesFields: any;
  excursionesAppend: any;
  excursionesRemove: any;
  excursionesUpdate: any;
}) {
  const [idx, setIdx] = useState<number>(0);
  const [excursion, setExcursion] = useState<Excursion | undefined>();
  const [excursionDialogOpen, setExcursionDialogOpen] =
    useState<boolean>(false);

  const handleSave = (index: number, excursion: Excursion) => {
    setExcursionDialogOpen(false);
    if (index >= 0) {
      excursionesUpdate(index, excursion);
    } else {
      excursionesAppend(excursion);
    }
  };

  const handleAddExcursion = () => {
    setIdx(-1);
    setExcursion(new Excursion());
    setExcursionDialogOpen(true);
  };

  const handleEditExcursion = (index: number) => {
    setIdx(index);
    setExcursion(excursionesFields[index]);
    setExcursionDialogOpen(true);
  };

  const handleRemoveExcursion = (index: number) => {
    excursionesRemove(index);
  };

  return (
    <>
      <ExcursionDialog
        excursionDialogOpen={excursionDialogOpen}
        setExcursionDialogOpen={setExcursionDialogOpen}
        index={idx}
        excursion={excursion}
        handleSave={handleSave}
      />
      <Grid item xs={12}>
        <Grid
          container
          alignItems='center'
          justifyContent='space-between'
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Typography variant='h6'>Excursiones</Typography>
          </Grid>
          <Grid item>
            <Fab
              size='small'
              color='primary'
              aria-label='add'
              onClick={handleAddExcursion}
            >
              <AddIcon />
            </Fab>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='caption' color='error'>
          {errors.excursiones?.root?.message}
        </Typography>
      </Grid>
      {excursionesFields.length > 0 && (
        <Grid item xs={12}>
          <TableContainer>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>Ciudad</TableCell>
                  <TableCell>Comentario</TableCell>
                  <TableCell align='right'>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {excursionesFields.map((excursion: any, index: number) => {
                  return (
                    <TableRow key={excursion.id} hover>
                      <TableCell>{excursion.ciudad}</TableCell>
                      <TableCell>{excursion.comentario}</TableCell>
                      <TableCell align='right'>
                        <IconButton
                          size='small'
                          onClick={() => handleEditExcursion(index)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          size='small'
                          onClick={() => handleRemoveExcursion(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </>
  );
}

export default ExcursionTable;
