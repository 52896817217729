import { redirect } from 'react-router-dom';

const BACKEND = process.env.REACT_APP_BACKEND;

export interface SignUpForm {
  email: string,
  password: string,
  sucursal: number,
  secret: string,
}

export type LoginResponse = {
  success: boolean;
  user: UserData;
  error: boolean;
}

export type UserData = {
  accessToken: string;
  refreshToken: string;
  email: string;
  sucursal: number;
  admin: boolean;
};

export async function login(email: string, password: string) {
  const url = `${BACKEND}/seguridad/login`;
  const method = 'POST';

  const settings = {
    method,
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      password,
    }),
  };

  const response = await fetch(url, settings);
  const { data } = await response.json();
  if (response.ok) {
    const user = {
      email: data.email,
      sucursal: data.sucursal,
      accessToken: data.accessToken,
      refreshToken: data.refreshToken,
      admin: data.admin
    };
    return {
      success: true,
      user
    };
  }
  return {
    success: false,
    user: undefined
  };
}

export const getCurrentUser = (): UserData | null => {
  const user = localStorage.getItem('user');
  if (user) {
    return JSON.parse(user);
  }
  return null;
};

export function authHeader() {
  const user = getCurrentUser();
  if (user && user.accessToken) {
    return 'Bearer ' + user.accessToken;
  }
  return '';
}

export function checkAuthLoader() {
  const user = getCurrentUser();
  if (!user) {
    return redirect('/signin');
  }
  return null;
}

export function checkAuthDashboardLoader() {
  const user = getCurrentUser();
  if (!user) {
    return redirect('/signin');
  }
  if (!Boolean(user.admin)) {
    return redirect('/');
  }
  return null;
}

export async function signup(form: SignUpForm) {
  const url = `${BACKEND}/seguridad/registrar`;
  const method = 'POST';

  const settings = {
    method,
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(form),
  };

  const response = await fetch(url, settings);
  const { message } = await response.json();
  if (response.ok) {
    return {
      error: false,
      message,
    };
  }
  return {
    error: true,
    message,
  };
}
