export default class Transporte {
  id?: number;
  contratoId?: number;
  compania?: string;
  vuelo?: string;
  clase?: string;
  fecha?: Date | null;
  origen?: string;
  destino?: string;
  sale?: Date | null;
  llega?: Date | null;
};
