import { useState } from 'react';
import {
  Grid,
  Typography,
  Fab,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PasajeroDialog from './pasajero-dialog';
import Pasajero from '../models/pasajero';

function PasajeroTable ({
  errors,
  pasajerosFields,
  pasajerosAppend,
  pasajerosRemove,
  pasajerosUpdate
}: {
  errors: any;
  pasajerosFields: any;
  pasajerosAppend: any;
  pasajerosRemove: any;
  pasajerosUpdate: any;
}) {
  const [idx, setIdx] = useState<number>(0);
  const [pasajero, setPasajero] = useState<Pasajero | undefined>();
  const [pasajeroDialogOpen, setPasajeroDialogOpen] = useState<boolean>(false);

  const handleSave = (index: number, pasajero: Pasajero) => {
    setPasajeroDialogOpen(false);
    if (index >= 0) {
      pasajerosUpdate(index, pasajero);
    } else {
      pasajerosAppend(pasajero);
    }
  };

  const handleAddPasajero = () => {
    setIdx(-1);
    setPasajero(new Pasajero());
    setPasajeroDialogOpen(true);
  };

  const handleEditPasajero = (index: number) => {
    setIdx(index);
    setPasajero(pasajerosFields[index]);
    setPasajeroDialogOpen(true);
  };

  const handleRemovePasajero = (index: number) => {
    pasajerosRemove(index);
  };

  return (
    <>
      <PasajeroDialog
        pasajeroDialogOpen={pasajeroDialogOpen}
        setPasajeroDialogOpen={setPasajeroDialogOpen}
        index={idx}
        pasajero={pasajero}
        handleSave={handleSave}
      />
      <Grid item xs={12}>
        <Grid
          container
          alignItems='center'
          justifyContent='space-between'
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Typography variant='h6'>Datos de Pasajeros</Typography>
          </Grid>
          <Grid item>
            <Fab
              size='small'
              color='primary'
              aria-label='add'
              onClick={handleAddPasajero}
            >
              <AddIcon />
            </Fab>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='caption' color='error'>
          {errors.pasajeros?.root?.message}
        </Typography>
      </Grid>
      {pasajerosFields.length > 0 && (
        <Grid item xs={12}>
          <TableContainer>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell key='apellido' component='th'>
                    Apellido y Nombre
                  </TableCell>
                  <TableCell key='documento' component='th'>
                    Documento
                  </TableCell>
                  <TableCell align='right'>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pasajerosFields.map((pasajero: any, index: number) => {
                  return (
                    <TableRow key={pasajero.id} hover>
                      <TableCell>
                        {pasajero.apellido}, {pasajero.nombre}
                      </TableCell>
                      <TableCell>
                        {pasajero.tipoDocumento} {pasajero.documento}
                      </TableCell>
                      <TableCell align='right'>
                        <IconButton
                          size='small'
                          onClick={() => handleEditPasajero(index)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          size='small'
                          onClick={() => handleRemovePasajero(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </>
  );
}

export default PasajeroTable;
