import { useState } from 'react';
import {
  Grid,
  Typography,
  Fab,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import HotelDialog from './hotel-dialog';
import Hotel from '../models/hotel';
import { handleDatePrint } from './utils';

function HotelTable ({
  errors,
  hotelesFields,
  hotelesAppend,
  hotelesRemove,
  hotelesUpdate
}: {
  errors: any;
  hotelesFields: any;
  hotelesAppend: any;
  hotelesRemove: any;
  hotelesUpdate: any;
}) {
  const [idx, setIdx] = useState<number>(0);
  const [hotel, setHotel] = useState<Hotel | undefined>();
  const [hotelDialogOpen, setHotelDialogOpen] = useState<boolean>(false);

  const handleSave = (index: number, hotel: Hotel) => {
    setHotelDialogOpen(false);
    if (index >= 0) {
      hotelesUpdate(index, hotel);
    } else {
      hotelesAppend(hotel);
    }
  };

  const handleAddHotel = () => {
    setIdx(-1);
    setHotel(new Hotel());
    setHotelDialogOpen(true);
  };

  const handleEditHotel = (index: number) => {
    setIdx(index);
    setHotel(hotelesFields[index]);
    setHotelDialogOpen(true);
  };

  const handleRemoveHotel = (index: number) => {
    hotelesRemove(index);
  };

  return (
    <>
      <HotelDialog
        hotelDialogOpen={hotelDialogOpen}
        setHotelDialogOpen={setHotelDialogOpen}
        index={idx}
        hotel={hotel}
        handleSave={handleSave}
      />
      <Grid item xs={12}>
        <Grid
          container
          alignItems='center'
          justifyContent='space-between'
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Typography variant='h6'>Hoteles</Typography>
          </Grid>
          <Grid item>
            <Fab
              size='small'
              color='primary'
              aria-label='add'
              onClick={handleAddHotel}
            >
              <AddIcon />
            </Fab>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='caption' color='error'>
          {errors.hoteles?.root?.message}
        </Typography>
      </Grid>
      {hotelesFields.length > 0 && (
        <Grid item xs={12}>
          <TableContainer>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>Check-In</TableCell>
                  <TableCell>Check-Out</TableCell>
                  <TableCell>Noches</TableCell>
                  <TableCell>Ciudad</TableCell>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Habitación</TableCell>
                  <TableCell align='right'>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {hotelesFields.map((hotel: any, index: number) => {
                  return (
                    <TableRow key={hotel.id} hover>
                      <TableCell>{handleDatePrint(hotel.checkIn)}</TableCell>
                      <TableCell>{handleDatePrint(hotel.checkOut)}</TableCell>
                      <TableCell>{hotel.noches}</TableCell>
                      <TableCell>{hotel.ciudad}</TableCell>
                      <TableCell>{hotel.nombre}</TableCell>
                      <TableCell>{hotel.tipoHabitacion}</TableCell>
                      <TableCell align='right'>
                        <IconButton
                          size='small'
                          onClick={() => handleEditHotel(index)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          size='small'
                          onClick={() => handleRemoveHotel(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </>
  );
}

export default HotelTable;
