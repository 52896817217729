import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    textAlign: 'center'
  },
  sucursal: {
    marginTop: 20
  },
  texto: {
    padding: 1,
    fontSize: 6
  },
  socialMedia: {
    marginTop: 20
  }
});

const SaonaViajesInstitucional = () => (
  <View style={styles.container}>
    <Text style={styles.texto}>D.N.S.T Ljo 10513</Text>
    <Text style={styles.texto}>Res 1065/99</Text>
    <View style={styles.sucursal}>
      <Text style={styles.texto}>Saenz 107</Text>
      <Text style={styles.texto}>Lomas de Zamora</Text>
      <Text style={styles.texto}>Tel 5275-0210</Text>
    </View>
    <View style={styles.sucursal}>
      <Text style={styles.texto}>Mitre 1110</Text>
      <Text style={styles.texto}>Adrogue</Text>
      <Text style={styles.texto}>Tel 4214-6210</Text>
    </View>
    <View style={styles.socialMedia}>
      <Text style={styles.texto}>info@saonaviajes.com.ar</Text>
      <Text style={styles.texto}>IG: Saona Viajes</Text>
    </View>
  </View>
);

export default SaonaViajesInstitucional;
