import { Controller } from 'react-hook-form';
import { TimeField } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

interface FormInputProps {
  name: string;
  control: any;
  label: string;
  setValue?: any;
  required: boolean;
  defaultValue?: any;
}

export const SaonaTimePicker = ({
  name,
  control,
  label,
  required,
  defaultValue
}: FormInputProps) => {
  let rules;
  if (required) {
    rules = {
      required: 'Debe ingresar un valor'
    };
  }
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState
      }) => (
        <TimeField
          defaultValue={dayjs(defaultValue)}
          format='HH:mm'
          value={dayjs(value)}
          onChange={(newValue) => {
            onChange(newValue);
          }}
          label={label}
          slotProps={{
            textField: {
              fullWidth: true,
              variant: 'standard',
              required: true,
              error: !!error,
              helperText: error ? error.message : null
            }
          }}
        />
      )}
    />
  );
};
