/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { generateDashboard } from '../components/api-dashboard';
import dayjs from 'dayjs';

interface MyProps {
  data: any[];
}

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  margin: '5%',
}));

const Grafico: React.FC<MyProps> = ({ data }) => {
  return (
    <ResponsiveContainer height={250} width='99%'>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' />
        <YAxis allowDecimals={false} />
        <Tooltip />
        <Legend />
        <Bar dataKey='lomas' fill='#f26520' name='Lomas' />
        <Bar dataKey='adrogue' fill='#167abb' name='Adrogue' />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default function DashboardPage() {
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await generateDashboard();
      setData(data);
    };

    fetchData().catch(console.error);
  }, []);

  return (
    <Grid
      container
      direction='row'
      justifyContent='space-between'
      alignItems='baseline'
    >
      <Grid item xs={12} sm={6}>
        <Item>
          <Typography>
            Clientes {dayjs(new Date()).year()} por sucursal
          </Typography>
          <Grafico data={data.clientesByMesSucursal} />
        </Item>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Item>
          <Typography>
            Contratos {dayjs(new Date()).year()} por sucursal
          </Typography>
          <Grafico data={data.contratosByMesSucursal} />
        </Item>
      </Grid>
    </Grid>
  );
}
