/* eslint-disable react-hooks/exhaustive-deps */
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React, {ChangeEvent, MouseEvent, useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {ClienteFormValues} from '../models/form-values';
import {findCliente, saveCliente} from '../components/api-cliente';
import ClienteForm from '../components/cliente-form';
import Loading from '../components/loading';
import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel
} from "@mui/material";
import Contrato from "../models/contrato";
import EditIcon from "@mui/icons-material/Edit";
import {handleDatePrint, OrderByDirection} from "../components/utils";
import {Search} from "../components/filtros";
import {findContratos} from "../components/api-contrato";
import {Cancel, Save} from "@mui/icons-material";

export default function ClienteEdit() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [cliente, setCliente] = useState<ClienteFormValues>();
    const [contratos, setContratos] = useState<Contrato[]>([]);
    const [search, setSearch] = useState<Search>({ label: '', value: '' });
    const [orderBy, setOrderBy] = useState<string>('numero');
    const [orderDirection, setOrderDirection] = useState<OrderByDirection>('desc');
    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(5);
    const [count, setCount] = useState<number>(0);

    const {
        register,
        handleSubmit,
        setValue,
        formState: {errors},
        control
    } = useForm<ClienteFormValues>({
        defaultValues: {
            id: '0',
            apellido: '',
            nombre: '',
            tipoDocumento: '',
            documento: '',
            fechaNacimiento: undefined,
            telefono: '',
            nacionalidad: '',
            direccion: '',
            email: ''
        }
    });

    const onSubmit = handleSubmit(async (data) => {
        setLoading(true);
        saveCliente(data).then((data) => {
            navigate('/clientes', {replace: true});
            setLoading(false);
        });
    });

    const handleSearch = (
        search: Search,
        orderBy: string,
        orderDirection: OrderByDirection,
        page: number,
        pageSize: number
    ) => {
        setLoading(true);
        findContratos(search, orderBy, orderDirection, page, pageSize).then((data) => {
            setContratos(data.result);
            setCount(data.count);
            setLoading(false);
        });
    };

    const handleSortRequest =
        (property: keyof any) =>
            (event: MouseEvent<HTMLTableCellElement>) => {
                let newOrderBy = orderBy;
                let newOrderDirection = orderDirection;
                if (property.toString() === orderBy) {
                    newOrderDirection = orderDirection === 'asc' ? 'desc' : 'asc';
                    setOrderDirection(newOrderDirection);
                } else {
                    newOrderDirection = 'asc';
                    setOrderDirection(newOrderDirection);
                    newOrderBy = property.toString();
                    setOrderBy(newOrderBy);
                }
                handleSearch(search, newOrderBy, newOrderDirection, page, pageSize);
            };

    const handleChangePage = (
        event: MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage);
        handleSearch(search, orderBy, orderDirection, newPage, pageSize);
    };

    const handleChangeRowsPerPage = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        const newPageSize = parseInt(event.target.value, 10);
        setPageSize(newPageSize);
        handleSearch(search, orderBy, orderDirection, page, newPageSize);
    };

    useEffect(() => {
        if (id !== '0') {
            setLoading(true);
            findCliente(id!).then((data) => {
                setCliente(data.result);
                setLoading(false);
            });
            setLoading(true);
            const searchByCliente: Search = { label: 'cliente_id', value: id! };
            setSearch(searchByCliente);
            findContratos(searchByCliente, orderBy, orderDirection, page, pageSize).then((data) => {
                setContratos(data.result);
                setCount(data.count);
                setLoading(false);
            });
        }
    }, [id]);

    useEffect(() => {
        if (cliente) {
            setValue('id', id!);
            setValue('apellido', cliente.apellido);
            setValue('nombre', cliente.nombre);
            setValue('fechaNacimiento', cliente.fechaNacimiento);
            setValue('nacionalidad', cliente.nacionalidad);
            setValue('tipoDocumento', cliente.tipoDocumento);
            setValue('documento', cliente.documento);
            setValue('direccion', cliente.direccion);
            setValue('telefono', cliente.telefono);
            setValue('email', cliente.email);
        }
    }, [cliente, setValue, id]);

    if (isLoading) return <Loading/>;

    return (
        <Container component='main' maxWidth='md' sx={{mb: 4}}>
            <Paper
                variant='outlined'
                sx={{my: {xs: 3, md: 6}, p: {xs: 2, md: 3}}}
            >
                <Typography component='h1' variant='h4' align='center' sx={{mb: 2}}>
                    {id === '0' ? 'Nuevo ' : ''}Cliente
                </Typography>
                <Box component='form' noValidate onSubmit={onSubmit}>
                    <ClienteForm register={register} errors={errors} control={control}/>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button
                            variant='contained'
                            onClick={onSubmit}
                            sx={{mt: 3, ml: 1}}
                            startIcon={<Save />}
                        >
                            GRABAR
                        </Button>
                        <Button
                            variant='contained'
                            sx={{mt: 3, ml: 1}}
                            color='secondary'
                            component={Link}
                            to='/clientes'
                            startIcon={<Cancel />}
                        >
                            VOLVER
                        </Button>
                    </Box>
                </Box>
            </Paper>
            <Paper
                variant='outlined'
                sx={{my: {xs: 3, md: 6}, p: {xs: 2, md: 3}}}
            >
                <Typography component='h1' variant='h4' align='center' sx={{mb: 2}}>
                    Contratos
                </Typography>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell key='numero' component='th'>
                                    <TableSortLabel
                                        active={orderBy === 'numero'}
                                        onClick={handleSortRequest('numero')}
                                        direction={orderBy === 'numero' ? orderDirection : 'asc'}
                                    >
                                        Número
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell key='destino' component='th'>
                                    <TableSortLabel
                                        active={orderBy === 'destino'}
                                        onClick={handleSortRequest('destino')}
                                        direction={orderBy === 'destino' ? orderDirection : 'asc'}
                                    >
                                        Destino
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell key='fechaContrato' component='th'>
                                    <TableSortLabel
                                        active={orderBy === 'fechaContrato'}
                                        onClick={handleSortRequest('fechaContrato')}
                                        direction={orderBy === 'fechaContrato' ? orderDirection : 'asc'}
                                    >
                                        Fecha Contrato
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell key='fechaViaje' component='th'>
                                    <TableSortLabel
                                        active={orderBy === 'fechaViaje'}
                                        onClick={handleSortRequest('fechaViaje')}
                                        direction={orderBy === 'fechaViaje' ? orderDirection : 'asc'}
                                    >
                                        Fecha Viaje
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell key='createdAt' component='th'>
                                    <TableSortLabel
                                        active={orderBy === 'createdAt'}
                                        onClick={handleSortRequest('createdAt')}
                                        direction={orderBy === 'createdAt' ? orderDirection : 'asc'}
                                    >
                                        Creado
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell component='th'/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {contratos.map((contrato: Contrato) => (
                                <TableRow key={contrato.id} hover>
                                    <TableCell scope='row'>{contrato.numero}</TableCell>
                                    <TableCell>{contrato.destino}</TableCell>
                                    <TableCell>{handleDatePrint(contrato.fechaContrato)}</TableCell>
                                    <TableCell>{handleDatePrint(contrato.fechaViaje)}</TableCell>
                                    <TableCell>{contrato.createdAt}</TableCell>
                                    <TableCell>
                                        <Link to={`/contratos/${contrato.id}`}>
                                            <IconButton size='small'>
                                                <EditIcon/>
                                            </IconButton>
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    count={count}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={pageSize}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    rowsPerPageOptions={[5, 10, 25]}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Paper>

        </Container>
    );
}
