/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import { useForm } from 'react-hook-form';
import Traslado from '../models/traslado';
import { SaonaTextField } from './SaonaTextField';
import { SaonaDatePicker } from './SaonaDatePicker';

function TrasladoDialog ({
  index,
  traslado,
  trasladoDialogOpen,
  setTrasladoDialogOpen,
  handleSave
}: {
  index: number;
  traslado: Traslado | undefined;
  trasladoDialogOpen: boolean;
  setTrasladoDialogOpen: (open: boolean) => void;
  handleSave: (index: number, traslado: Traslado) => void;
}) {
  const {
    handleSubmit,
    setValue,
    control
  } = useForm<Traslado>({
    defaultValues: {
      ciudad: '',
      fecha: null,
      desde: '',
      hasta: '',
      otros: ''
    }
  });

  useEffect(() => {
    if (traslado) {
      setValue('ciudad', traslado.ciudad);
      setValue('fecha', traslado.fecha);
      setValue('desde', traslado.desde);
      setValue('hasta', traslado.hasta);
      setValue('otros', traslado.otros);
    }
  }, [traslado]);

  const handleGrabar = handleSubmit((data: any) => {
    traslado = { ...data };
    handleSave(index, traslado!);
  });

  const handleClose = () => {
    setTrasladoDialogOpen(false);
  };

  return (
    <Dialog open={trasladoDialogOpen} onClose={handleClose} disableRestoreFocus>
      <DialogTitle>Datos de Traslado</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <SaonaTextField
              autoFocus
              required
              name='ciudad'
              control={control}
              label='Ciudad'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SaonaDatePicker
              required
              name='fecha'
              control={control}
              label='Fecha'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SaonaTextField
              required
              name='desde'
              control={control}
              label='Desde'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SaonaTextField
              required
              name='hasta'
              control={control}
              label='Hasta'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SaonaTextField
              required
              name='otros'
              control={control}
              label='Otros'
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleGrabar}>ACEPTAR</Button>
        <Button onClick={handleClose} color='secondary'>CANCELAR</Button>
      </DialogActions>
    </Dialog>
  );
}

export default TrasladoDialog;
