/* eslint-disable jsx-a11y/alt-text */
import {
  Document,
  Page,
  View,
  StyleSheet,
  PDFViewer,
  Image
} from '@react-pdf/renderer';
import SaonaViajesInstitucional from './SaonaViajesInstitucional';
import ContratoPdf from './ContratoPdf';
import { ContratoFormValues } from '../../models/form-values';

const styles = StyleSheet.create({
  viewer: {
    width: window.innerWidth,
    height: window.innerHeight
  },
  page: {
    padding: 10
  },
  container: {
    flex: 1,
    flexDirection: 'row'
  },
  image: {
    marginBottom: 10
  },
  leftColumn: {
    flexDirection: 'column',
    width: 80,
    paddingTop: 10,
    paddingRight: 10
  }
});

function SaonaViajesContrato ({ contrato } : { contrato: ContratoFormValues }) {
  return (
    <>
      { contrato && 
        <PDFViewer style={styles.viewer}>
          <Document>
            <Page style={styles.page} size='A4'>
              <View style={styles.container}>
                <View style={styles.leftColumn}>
                  <Image style={styles.image} src='/logo-saona-HD.jpg' />
                  <SaonaViajesInstitucional />
                </View>
                <ContratoPdf contrato={contrato} />
              </View>
            </Page>
          </Document>
        </PDFViewer>
      }
    </>
  );
}

export default SaonaViajesContrato;
