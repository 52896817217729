/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Box } from '@mui/material';

export default function HomePage() {
  return (
    <React.Fragment>
      <div style={{ width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            p: 2,
            m: 2,
            bgcolor: 'background.paper',
          }}
        >
          <Box
            component='img'
            alt='Saona Viajes Logo'
            src='/logo-saona-HD.webp'
            width={"50%"}
          />
        </Box>
      </div>
    </React.Fragment>
  );
}
