import { CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import theme from './components/theme';
import { checkAuthDashboardLoader, checkAuthLoader, getCurrentUser } from './components/api-seguridad';
import HomePage from './pages/home';
import ContratoHome from './pages/contratos';
import ContratoEdit from './pages/contrato';
import ContratoPrint from './pages/contrato-print';
import ClienteHome from './pages/clientes';
import ClienteEdit from './pages/cliente';
import RootLayout from './pages/root';
import LogoutPage from './pages/logout';
import AuthenticationPage, { authAction } from './pages/authentication';
import SignUpPage from './pages/signup';
import DashboardPage from './pages/dashboard';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    id: 'root',
    loader: getCurrentUser,
    children: [
      {
        path: 'signup',
        element: <SignUpPage />
      },
      {
        path: 'signin',
        element: <AuthenticationPage />,
        action: authAction,
      },
      {
        path: 'logout',
        element: <LogoutPage />,
        loader: checkAuthLoader,
      },
      {
        path: '',
        element: <HomePage />,
        loader: checkAuthLoader,
      },
      {
        path: 'dashboard',
        element: <DashboardPage />,
        loader: checkAuthDashboardLoader,
      },
      {
        path: 'contratos',
        element: <ContratoHome />,
        loader: checkAuthLoader,
      },
      {
        path: 'contratos/:id',
        element: <ContratoEdit />,
        loader: checkAuthLoader,
      },
      {
        path: 'contratos/:id/print',
        element: <ContratoPrint />,
        loader: checkAuthLoader,
      },
      {
        path: 'clientes',
        element: <ClienteHome />,
        loader: checkAuthLoader,
      },
      {
        path: 'clientes/:id',
        element: <ClienteEdit />,
        loader: checkAuthLoader,
      },
    ],
  },
]);

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <GlobalStyles
          styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }}
        />
        <CssBaseline />
        <RouterProvider router={router} />
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
