import {useState} from 'react';
import {
  Fab,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ConceptoDialog from './concepto-dialog';
import Concepto from '../models/concepto';
import {handleMoneyPrint} from "./utils";

function ConceptoTable ({
  errors,
  conceptosFields,
  conceptosAppend,
  conceptosRemove,
  conceptosUpdate
}: {
  errors: any;
  conceptosFields: any;
  conceptosAppend: any;
  conceptosRemove: any;
  conceptosUpdate: any;
}) {
  const [idx, setIdx] = useState<number>(0);
  const [concepto, setConcepto] = useState<Concepto | undefined>();
  const [conceptoDialogOpen, setConceptoDialogOpen] =
    useState<boolean>(false);

  const handleSave = (index: number, concepto: Concepto) => {
    setConceptoDialogOpen(false);
    if (index >= 0) {
      conceptosUpdate(index, concepto);
    } else {
      conceptosAppend(concepto);
    }
  };

  const handleAddConcepto = () => {
    setIdx(-1);
    setConcepto(new Concepto());
    setConceptoDialogOpen(true);
  };

  const handleEditConcepto = (index: number) => {
    setIdx(index);
    setConcepto(conceptosFields[index]);
    setConceptoDialogOpen(true);
  };

  const handleRemoveConcepto = (index: number) => {
    conceptosRemove(index);
  };

  return (
    <>
      <ConceptoDialog
        conceptoDialogOpen={conceptoDialogOpen}
        setConceptoDialogOpen={setConceptoDialogOpen}
        index={idx}
        concepto={concepto}
        handleSave={handleSave}
      />
      <Grid item xs={12}>
        <Grid
          container
          alignItems='center'
          justifyContent='space-between'
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Typography variant='h6'>Precio por Pasajero</Typography>
          </Grid>
          <Grid item>
            <Fab
              size='small'
              color='primary'
              aria-label='add'
              onClick={handleAddConcepto}
            >
              <AddIcon />
            </Fab>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='caption' color='error'>
          {errors.conceptos?.root?.message}
        </Typography>
      </Grid>
      {conceptosFields.length > 0 && (
        <Grid item xs={12}>
          <TableContainer>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>Concepto</TableCell>
                  <TableCell>Moneda</TableCell>
                  <TableCell>Valor</TableCell>
                  <TableCell align='right'>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {conceptosFields.map((concepto: any, index: number) => {
                  return (
                    <TableRow key={concepto.id} hover>
                      <TableCell>{concepto.concepto}</TableCell>
                      <TableCell>{concepto.moneda}</TableCell>
                      <TableCell>{handleMoneyPrint(concepto.moneda, concepto.valor)}</TableCell>
                      <TableCell align='right'>
                        <IconButton
                          size='small'
                          onClick={() => handleEditConcepto(index)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          size='small'
                          onClick={() => handleRemoveConcepto(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </>
  );
}

export default ConceptoTable;
