import { Grid, TextField, Typography } from '@mui/material';
import PasajeroTable from './pasajero-table';
import TransporteTable from './transporte-table';
import HotelTable from './hotel-table';
import TrasladoTable from './traslado-table';
import ExcursionTable from './excursion-table';
import ConceptoTable from './concepto-table';
import { SaonaDatePicker } from './SaonaDatePicker';

function ContratoForm ({
  register,
  errors,
  control,
  pasajerosFields,
  pasajerosAppend,
  pasajerosRemove,
  pasajerosUpdate,
  transportesFields,
  transportesAppend,
  transportesRemove,
  transportesUpdate,
  hotelesFields,
  hotelesAppend,
  hotelesRemove,
  hotelesUpdate,
  trasladosFields,
  trasladosAppend,
  trasladosRemove,
  trasladosUpdate,
  excursionesFields,
  excursionesAppend,
  excursionesRemove,
  excursionesUpdate,
  conceptosFields,
  conceptosAppend,
  conceptosRemove,
  conceptosUpdate
}: {
  register: any;
  errors: any;
  control: any;
  pasajerosFields: any;
  pasajerosAppend: any;
  pasajerosRemove: any;
  pasajerosUpdate: any;
  transportesFields: any;
  transportesAppend: any;
  transportesRemove: any;
  transportesUpdate: any;
  hotelesFields: any;
  hotelesAppend: any;
  hotelesRemove: any;
  hotelesUpdate: any;
  trasladosFields: any;
  trasladosAppend: any;
  trasladosRemove: any;
  trasladosUpdate: any;
  excursionesFields: any;
  excursionesAppend: any;
  excursionesRemove: any;
  excursionesUpdate: any;
  conceptosFields: any;
  conceptosAppend: any;
  conceptosRemove: any;
  conceptosUpdate: any;
}) {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <SaonaDatePicker
          required
          name='fechaContrato'
          control={control}
          label='Fecha Contrato'
          defaultValue={null}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id='destino'
          label='Destino'
          fullWidth
          variant='standard'
          autoComplete='off'
          {...register('destino', {
            required: 'Debe ingresar un valor.'
          })}
          error={!!errors.destino}
          helperText={errors.destino?.message}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label='Apellido y Nombre'
          fullWidth
          variant='standard'
          {...register('clienteNombre')}
          InputProps={{
              disabled: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label='Dirección'
          fullWidth
          variant='standard'
          {...register('clienteDireccion')}
          InputProps={{
              disabled: true
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SaonaDatePicker
          required
          name='fechaViaje'
          control={control}
          label='Fecha Viaje'
          defaultValue={null}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label='Teléfono'
          fullWidth
          variant='standard'
          {...register('clienteTelefono')}
          InputProps={{
              disabled: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label='E-Mail'
          fullWidth
          variant='standard'
          {...register('clienteEmail')}
          InputProps={{
              disabled: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          id='operador'
          label='Operador'
          fullWidth
          variant='standard'
          autoComplete='off'
          {...register('operador')}
        />
      </Grid>
      <PasajeroTable
        errors={errors}
        pasajerosFields={pasajerosFields}
        pasajerosAppend={pasajerosAppend}
        pasajerosRemove={pasajerosRemove}
        pasajerosUpdate={pasajerosUpdate}
      />
      <TransporteTable
        errors={errors}
        transportesFields={transportesFields}
        transportesAppend={transportesAppend}
        transportesRemove={transportesRemove}
        transportesUpdate={transportesUpdate}
      />
      <HotelTable
        errors={errors}
        hotelesFields={hotelesFields}
        hotelesAppend={hotelesAppend}
        hotelesRemove={hotelesRemove}
        hotelesUpdate={hotelesUpdate}
      />
      <TrasladoTable
        errors={errors}
        trasladosFields={trasladosFields}
        trasladosAppend={trasladosAppend}
        trasladosRemove={trasladosRemove}
        trasladosUpdate={trasladosUpdate}
      />
      <ExcursionTable
        errors={errors}
        excursionesFields={excursionesFields}
        excursionesAppend={excursionesAppend}
        excursionesRemove={excursionesRemove}
        excursionesUpdate={excursionesUpdate}
      />
      <Grid item xs={12}>
        <Grid
          container
          alignItems='center'
          justifyContent='space-between'
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Typography variant='h6'>Servicios Adicionales / Observaciones</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='adicionales'
          multiline
          rows={2}
          // maxRows={Infinity}
          fullWidth
          autoComplete='off'
          {...register('adicionales')}
        />
      </Grid>
      <ConceptoTable
        errors={errors}
        conceptosFields={conceptosFields}
        conceptosAppend={conceptosAppend}
        conceptosRemove={conceptosRemove}
        conceptosUpdate={conceptosUpdate}
      />
    </Grid>
  );
}

export default ContratoForm;
