/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, MouseEvent, ChangeEvent } from 'react';
import {
  Button,
  Grid,
  IconButton,
  Paper,
  TextField,
  Toolbar,
  AppBar,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TablePagination,
  TableFooter,
  TableBody,
  TableSortLabel,
  InputAdornment
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import {CancelRounded, FileDownload, PersonAdd, PersonSearch, TravelExplore} from '@mui/icons-material';
import { Search } from '../components/filtros';
import { OrderByDirection, handleDateTimePrint } from '../components/utils';
import {findClientes, descargarClientes} from '../components/api-cliente';
import Cliente from '../models/cliente';
import Loading from '../components/loading';
import { Link } from 'react-router-dom';

export default function ClienteHome () {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [clientes, setClientes] = useState<Cliente[]>([]);
  const [search, setSearch] = useState<Search>({ label: '', value: '' });
  const [orderBy, setOrderBy] = useState<string>('createdAt');
  const [orderDirection, setOrderDirection] = useState<OrderByDirection>('desc');
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(5);
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    handleSearch(search, orderBy, orderDirection, page, pageSize);
  }, []);

  const handleSearch = (
    search: Search,
    orderBy: string,
    orderDirection: OrderByDirection,
    page: number,
    pageSize: number
  ) => {
    setLoading(true);
    findClientes(search, orderBy, orderDirection, page, pageSize).then((data) => {
      setClientes(data.result);
      setCount(data.count);
      setLoading(false);
    });
  };

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    handleSearch(search, orderBy, orderDirection, newPage, pageSize);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const newPageSize = parseInt(event.target.value, 10);
    setPageSize(newPageSize);
    handleSearch(search, orderBy, orderDirection, page, newPageSize);
  };

  const handleDescargar = () => {
    setLoading(true);
    descargarClientes(search, orderBy, orderDirection).then( (response) => {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(response.data);
      link.download = response.filename;
      link.click();
      setLoading(false);
    });
  };

  const handleSortRequest =
    (property: keyof any) =>
      (event: MouseEvent<HTMLTableCellElement>) => {
        let newOrderBy = orderBy;
        let newOrderDirection = orderDirection;
        if (property.toString() === orderBy) {
          newOrderDirection = orderDirection === 'asc' ? 'desc' : 'asc';
          setOrderDirection(newOrderDirection);
        } else {
          newOrderDirection = 'asc';
          setOrderDirection(newOrderDirection);
          newOrderBy = property.toString();
          setOrderBy(newOrderBy);
        }
        handleSearch(search, newOrderBy, newOrderDirection, page, pageSize);
      };

  const handleFilterValueChange = (event: any) => {
    setSearch({ label: search.label, value: event.target.value });
  };

  const handleFilterValueClear = (event: any) => {
    const emptySearch = { label: search.label, value: '' };
    setPage(0);
    setSearch(emptySearch);
    handleSearch(emptySearch, orderBy, orderDirection, 0, pageSize);
  };

  const handleFilterSearch = () => {
    setPage(0);
    handleSearch(search, 'apellido', 'asc', 0, pageSize);
  };

  if (isLoading) return <Loading />;
  if (!clientes) return <p>No profile data</p>;

  return (
    <Paper sx={{ margin: 'auto', overflow: 'hidden', m: 2 }}>
      <AppBar
        position='static'
        color='default'
        elevation={0}
        sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
      >
        <Toolbar>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs>
              <TextField
                id='filter'
                name='filter'
                autoComplete='off'
                placeholder='Buscar por apellido, nombre, documento, teléfono, dirección, email'
                type='text'
                variant='standard'
                fullWidth
                onChange={handleFilterValueChange}
                value={search.value}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: search.value && (
                    <IconButton
                      onClick={handleFilterValueClear}
                    ><CancelRounded />
                    </IconButton>
                  )
                }}
              />
            </Grid>
            <Grid item>
              <Button
                disabled={search.value === ''}
                variant='contained'
                sx={{ mr: 1 }}
                onClick={handleFilterSearch}
                startIcon={<PersonSearch />}
              >
                BUSCAR
              </Button>
              <Button
                variant='contained'
                sx={{ mr: 1 }}
                component={Link} 
                to='/clientes/0'
                color='success'
                startIcon={<PersonAdd />}
              >
                NUEVO
              </Button>
              <Button
                variant='contained'
                sx={{ mr: 1 }}
                onClick={handleDescargar}
                color='info'
                startIcon={<FileDownload />}
              >
                DESCARGAR
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell key='apellido' component='th'>
                <TableSortLabel
                  active={orderBy === 'apellido'}
                  onClick={handleSortRequest('apellido')}
                  direction={orderBy === 'apellido' ? orderDirection : 'asc'}
                >
                  Apellido y Nombre
                </TableSortLabel>
              </TableCell>
              <TableCell key='documento' component='th'>
                <TableSortLabel
                  active={orderBy === 'documento'}
                  onClick={handleSortRequest('documento')}
                  direction={orderBy === 'documento' ? orderDirection : 'asc'}
                >
                  Documento
                </TableSortLabel>
              </TableCell>
              <TableCell key='telefono' component='th'>
                <TableSortLabel
                  active={orderBy === 'telefono'}
                  onClick={handleSortRequest('telefono')}
                  direction={orderBy === 'telefono' ? orderDirection : 'asc'}
                >
                  Teléfono
                </TableSortLabel>
              </TableCell>
              <TableCell key='createdAt' component='th'>
                <TableSortLabel
                  active={orderBy === 'createdAt'}
                  onClick={handleSortRequest('createdAt')}
                  direction={orderBy === 'createdAt' ? orderDirection : 'asc'}
                >
                  Creado
                </TableSortLabel>
              </TableCell>
              <TableCell key='updatedAt' component='th'>
                <TableSortLabel
                  active={orderBy === 'updatedAt'}
                  onClick={handleSortRequest('updatedAt')}
                  direction={orderBy === 'updatedAt' ? orderDirection : 'asc'}
                >
                  Modificado
                </TableSortLabel>
              </TableCell>
              <TableCell key='countContratos' component='th'>
                  Contratos
              </TableCell>
              <TableCell component='th' />
            </TableRow>
          </TableHead>
          <TableBody>
            {clientes.map((cliente: Cliente) => (
              <TableRow key={cliente.id} hover>
                <TableCell scope='row'>
                  {cliente.apellido}, {cliente.nombre}
                </TableCell>
                <TableCell>
                  {cliente.tipoDocumento} {cliente.documento}
                </TableCell>
                <TableCell>{cliente.telefono}</TableCell>
                <TableCell title={`por ${cliente.createdBy}`} >{handleDateTimePrint(cliente.createdAt)}</TableCell>
                <TableCell title={`por ${cliente.updatedBy}`} >{handleDateTimePrint(cliente.updatedAt)}</TableCell>
                <TableCell>{cliente.countContratos.toString()}</TableCell>
                <TableCell>
                  <Link to={`/clientes/${cliente.id}`} title={"Editar/Ver cliente"}>
                    <IconButton size='small'>
                      <EditIcon />
                    </IconButton>
                  </Link> 
                  <Link to={`/contratos/0?clienteId=${cliente.id}`} title={"Nuevo contrato"}>
                    <IconButton size='small'>
                      <NoteAddIcon />
                    </IconButton>
                  </Link> 
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={pageSize}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
}
