/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import { useForm } from 'react-hook-form';
import Pasajero from '../models/pasajero';
import paises from './nacionalidad';
import tiposDocumento from './tiposDocumento';
import { SaonaTextField } from './SaonaTextField';
import { SaonaAutocomplete } from './SaonaAutocomplete';
import { SaonaDatePicker } from './SaonaDatePicker';

function PasajeroDialog ({
  index,
  pasajero,
  pasajeroDialogOpen,
  setPasajeroDialogOpen,
  handleSave
}: {
  index: number;
  pasajero: Pasajero | undefined;
  pasajeroDialogOpen: boolean;
  setPasajeroDialogOpen: (open: boolean) => void;
  handleSave: (index: number, pasajero: Pasajero) => void;
}) {
  const {
    handleSubmit,
    setValue,
    control
  } = useForm<Pasajero>({
    defaultValues: {
      nombre: '',
      apellido: '',
      fechaNacimiento: null,
      nacionalidad: null,
      tipoDocumento: null,
      documento: '',
      cuil: ''
    }
  });

  useEffect(() => {
    if (pasajero) {
      setValue('nombre', pasajero.nombre);
      setValue('apellido', pasajero.apellido);
      setValue('fechaNacimiento', pasajero.fechaNacimiento);
      setValue('nacionalidad', pasajero.nacionalidad);
      setValue('tipoDocumento', pasajero.tipoDocumento);
      setValue('documento', pasajero.documento);
      setValue('cuil', pasajero.cuil);
    }
  }, [pasajero]);

  const handleGrabar = handleSubmit((data : any) => {
    pasajero = { ...data };
    handleSave(index, pasajero!);
  });

  const handleClose = () => {
    setPasajeroDialogOpen(false);
  };

  return (
    <Dialog open={pasajeroDialogOpen} onClose={handleClose}>
      <DialogTitle>Datos de Pasajero</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <SaonaTextField
              required
              name='apellido'
              control={control}
              label='Apellido'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SaonaTextField
              required
              name='nombre'
              control={control}
              label='Nombre'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SaonaDatePicker
              required
              name='fechaNacimiento'
              control={control}
              label='Fecha Nacimiento'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SaonaAutocomplete
              required={false}
              name='nacionalidad'
              control={control}
              label='Nacionalidad'
              options={paises}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SaonaAutocomplete
              required
              name='tipoDocumento'
              control={control}
              label='Tipo Documento'
              options={tiposDocumento}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SaonaTextField
              required
              name='documento'
              control={control}
              label='Documento'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SaonaTextField
                required={false}
                name='cuil'
                control={control}
                label='CUIL/CUIT'
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleGrabar}>ACEPTAR</Button>
        <Button onClick={handleClose} color='secondary'>
          CANCELAR
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PasajeroDialog;
