import {useEffect} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from '@mui/material';
import {useForm} from 'react-hook-form';
import Concepto from '../models/concepto';
import {SaonaTextField} from './SaonaTextField';
import {SaonaAutocomplete} from "./SaonaAutocomplete";
import monedas from "./monedas";

function ConceptoDialog ({
  index,
  concepto,
  conceptoDialogOpen,
  setConceptoDialogOpen,
  handleSave
}: {
  index: number;
  concepto: Concepto | undefined;
  conceptoDialogOpen: boolean;
  setConceptoDialogOpen: (open: boolean) => void;
  handleSave: (index: number, concepto: Concepto) => void;
}) {
  const {
    handleSubmit,
    setValue,
    control
  } = useForm<Concepto>({
    defaultValues: {
      concepto: '',
      moneda: '',
      valor: 0
    }
  });

  useEffect(() => {
    if (concepto) {
      setValue('concepto', concepto.concepto);
      setValue('moneda', concepto.moneda);
      setValue('valor', concepto.valor);
    }
  }, [concepto, setValue]);

  const handleGrabar = handleSubmit((data: any) => {
    concepto = { ...data };
    handleSave(index, concepto!);
  });

  const handleClose = () => {
    setConceptoDialogOpen(false);
  };

  return (
    <Dialog open={conceptoDialogOpen} onClose={handleClose} disableRestoreFocus>
      <DialogTitle>Datos de Concepto</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <SaonaTextField
              autoFocus
              required={true}
              name='concepto'
              control={control}
              label='Concepto'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SaonaAutocomplete
              required={false}
              name='moneda'
              control={control}
              label='Moneda'
              options={monedas}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SaonaTextField
              required={false}
              name='valor'
              control={control}
              label='Valor'
              type='number'
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleGrabar}>ACEPTAR</Button>
        <Button onClick={handleClose} color='secondary'>
          CANCELAR
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConceptoDialog;
